import { Badge, Button, Drawer, Stack } from '@mui/material';
import { Iconify } from '../Iconify';
import type { FC } from 'react';
import React, { useState } from 'react';

import type { ConfigType, FilterDefinition } from './declarations/type';
import { NumberRange } from './commonComponents/NumberRange';
import { DateRange } from './commonComponents/DateRange';
import { useTranslation } from 'react-i18next';
import type { TrackEvent } from '@pflegenavi/frontend/tracking';

interface FiltersProps {
  name: string;
  filters: ConfigType<any, any, any>['filters'];
  trackEvent: TrackEvent<'PaginatedTable'>;
  onChange: (value: any) => void;
}

export const Filters: FC<FiltersProps> = ({
  filters,
  name,
  trackEvent,
  onChange,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const activeFilters = filters?.activeFilters ?? 11;
  return (
    <>
      <Button
        sx={{ m: 1 }}
        variant="outlined"
        startIcon={
          <Badge
            badgeContent={activeFilters}
            color="secondary"
            max={99}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Iconify
              icon={activeFilters ? 'eva:funnel-fill' : 'eva:funnel-outline'}
              width={20}
            />
          </Badge>
        }
        onClick={() => {
          void trackEvent({
            action: 'filterOpened',
            label: name,
          });
          setOpen(true);
        }}
      >
        {t('common.filter')}
      </Button>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        ModalProps={{
          BackdropProps: {
            invisible: true,
          },
        }}
      >
        <Stack style={{ width: 340 }} role="presentation" gap={2} sx={{ p: 2 }}>
          {filters?.items?.map((filter) => (
            <FilterSwitch
              name={name}
              trackEvent={trackEvent}
              onChange={onChange}
              key={filter.key}
              filter={filter}
            />
          ))}
        </Stack>
      </Drawer>
    </>
  );
};

interface FilterSwitchProps {
  name: string;
  filter: FilterDefinition<any>;
  trackEvent: TrackEvent<'PaginatedTable'>;
  onChange: (value: any) => void;
}

const FilterSwitch: FC<FilterSwitchProps> = ({
  filter,
  name,
  trackEvent,
  onChange,
}) => {
  switch (filter.type) {
    case 'custom':
      break;
    case 'boolean':
      break;
    case 'numberRange':
      return (
        <NumberRange
          onRangeChange={(min, max) => {
            void trackEvent({
              action: 'filterChanged',
              property: filter.key,
              label: name,
            });
            const value = { from: min ?? undefined, to: max ?? undefined };
            onChange(value);
            return filter.onChange(value);
          }}
          minValue={filter.min}
          maxValue={filter.max}
          label={filter.label}
        />
      );
    case 'dateRange':
      return (
        <DateRange
          label={filter.label}
          range={filter.range}
          onChange={(value) => {
            void trackEvent({
              action: 'filterChanged',
              property: filter.key,
              label: name,
            });
            onChange(value);
            return filter.onChange(value);
          }}
        />
      );
  }

  return null;
};
