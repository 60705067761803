import { useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';
import { useDebouncedSetQueryParams } from './useDebouncedSetQueryParams';

interface UseTablePaginationProps<TColumn = string> {
  defaultOrderBy: TColumn;
  defaultOrder?: 'asc' | 'desc';
  defaultCurrentPage?: number;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,complexity
export const useTableWithUrlParamsPagination = <
  TColumn extends string = string
>(
  props: UseTablePaginationProps<TColumn>
) => {
  const [searchParams] = useSearchParams();

  const { debouncedSetQueryParams } = useDebouncedSetQueryParams();

  const orderBy = (searchParams.get('orderBy') ||
    props.defaultOrderBy) as TColumn;
  const order = (searchParams.get('order') || props.defaultOrder || 'asc') as
    | 'asc'
    | 'desc';
  const page =
    Number(searchParams.get('page')) || props.defaultCurrentPage || 0;

  const setPage = useCallback(
    (value: number) => {
      debouncedSetQueryParams({ page: value });
    },
    [debouncedSetQueryParams]
  );

  const setOrderBy = useCallback(
    (value: TColumn) => {
      debouncedSetQueryParams({ orderBy: value });
      setPage(0);
    },
    [debouncedSetQueryParams, setPage]
  );

  const setOrder = useCallback(
    (value: 'asc' | 'desc') => {
      debouncedSetQueryParams({ order: value });
      setPage(0);
    },
    [debouncedSetQueryParams, setPage]
  );

  return {
    orderBy,
    order,
    page,
    setOrderBy,
    setOrder,
    setPage,
  };
};
