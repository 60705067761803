import type { Theme } from '@mui/material/styles';
import type { Components } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Popover(theme: Theme): Pick<Components, 'MuiPopover'> {
  return {
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: theme.customShadows.dropdown,
          borderRadius: Number(theme.shape.borderRadius) * 1.5,
        },
      },
    },
  };
}
