import { useState } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useTabs = (defaultValues?: string) => {
  const [currentTab, setCurrentTab] = useState(defaultValues || '');

  return {
    currentTab,
    onChangeTab: (
      event: React.SyntheticEvent<Element, Event>,
      newValue: any
    ) => {
      setCurrentTab(newValue);
    },
    setCurrentTab,
  };
};
