import { styled } from '@mui/material';
import type { TooltipProps } from '@mui/material/Tooltip';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export const NoMaxWidthTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});

export const MaxWidthTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ),
  { shouldForwardProp: (prop) => prop !== 'maxWidth' }
)<{ maxWidth: string | number }>(({ maxWidth }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth,
  },
}));
