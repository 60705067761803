import type { FC } from 'react';
import * as React from 'react';
import { useMemo } from 'react';
import { styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';

interface MoneyAmountCircleProps {
  amount: number;
  textColor?: string;
  backgroundColor?: string;
}

export const MoneyAmountCircle: FC<MoneyAmountCircleProps> = ({
  amount,
  textColor = 'primary.main',
  backgroundColor,
}) => {
  const { t } = useTranslation();

  const smallScreens = useMediaQuery('(max-width:900px)');

  const { amountStringBeforeDecimal, amountStringAfterDecimal } =
    useMemo(() => {
      return {
        amountStringBeforeDecimal: Math.trunc(amount / 100),
        amountStringAfterDecimal: `.${Math.abs(amount % 100)
          .toString()
          .padStart(2, '0')}`,
      };
    }, [amount]);

  return (
    <DropZoneStyle>
      <PlaceholderStyle
        className="placeholder"
        sx={{ backgroundColor: backgroundColor ?? 'background.neutral' }}
      >
        <Typography variant="caption" sx={{ color: textColor }}>
          {t('common.eur')}
        </Typography>
        <Typography
          variant={smallScreens ? 'h5' : 'h4'}
          sx={{ lineHeight: 1, color: textColor }}
        >
          {amountStringBeforeDecimal}
        </Typography>
        <Typography variant="caption" sx={{ lineHeight: 1, color: textColor }}>
          {amountStringAfterDecimal}
        </Typography>
      </PlaceholderStyle>
    </DropZoneStyle>
  );
};

const DropZoneStyle = styled('div')({
  zIndex: 0,
  width: '100%',
  height: '100%',
  outline: 'none',
  display: 'flex',
  overflow: 'hidden',
  borderRadius: '50%',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  '& > *': { width: '100%', height: '100%' },
});

const PlaceholderStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.neutral,
}));
