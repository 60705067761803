import React from 'react';
import type { SystemCssProperties } from '@mui/system';
import { Table, TableBody, TableContainer } from '@mui/material';

import type {
  RowsPerPageType,
  TableKeyType,
  UseTableProps,
} from '../hooks/useTable';
import { emptyRows } from '../hooks/useTable';

import TableEmptyRows from './TableEmptyRows';
import type { HeadCell } from './TableHeadCustom';
import TableHeadCustom from './TableHeadCustom';
import TableSkeleton from './TableSkeleton';
import { NoDataDefaultTable } from './NoDataDefaultTable';
import { CustomTablePagination } from '../CustomTablePagination';
import { ROWS_PER_PAGE } from '@pflegenavi/shared/constants';
import type { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SxProps extends SystemCssProperties<Theme> {}

export interface DefaultTableProps<
  T extends { id: string },
  TColumn extends string
> extends Omit<UseTableProps<TColumn, T>, 'onSelectAllRows' | 'rowsPerPage'> {
  data?: T[];
  handleDeleteRow?: (id: string) => void;
  handleEditRow?: (id: string) => void;
  isFiltered: boolean;
  isError: boolean;
  isLoading: boolean;
  Modals: React.ReactNode;
  additionalHeaderRow?: React.ReactNode;
  tableHead: Array<HeadCell<TColumn>>;
  TableRow: React.ComponentType<{
    row: T;
    selected: boolean;
    onSelectRow?: VoidFunction;
    handleClickDelete?: (id: string) => void;
    onEditRow?: (id: string) => void;
  }>;
  onSelectAllRows?: (checked: boolean) => void;
  rowsPerPage: RowsPerPageType;
  dataAvailableBeforeFilter: boolean;
  tableKey: TableKeyType;
  addActionColumn?: boolean;
  removeFromTabSelection?: boolean;
  isNoDataThisMonth?: boolean;
  customNoDataLabel?: string;
  size?: 'small' | 'medium';
  tableSx?: SxProps;
  cellSx?: SxProps;
}

// eslint-disable-next-line complexity
export function DefaultTable<T extends { id: string }, TColumn extends string>({
  order,
  orderBy,
  selected,
  onSort,
  onSelectAllRows,
  page,
  rowsPerPage,
  onSelectRow,
  onChangePage,
  onChangeRowsPerPage,
  data,
  handleDeleteRow,
  handleEditRow,
  isFiltered,
  isError,
  isLoading,
  tableHead,
  Modals,
  TableRow,
  dataAvailableBeforeFilter,
  tableKey,
  addActionColumn,
  removeFromTabSelection = false,
  isNoDataThisMonth = false,
  customNoDataLabel,
  size = 'medium',
  tableSx,
  additionalHeaderRow,
}: DefaultTableProps<T, TColumn>): JSX.Element {
  const getDynamicRowsPerPage = () => {
    const dataSize = (data ?? []).length;
    for (const rowsPerPage of ROWS_PER_PAGE) {
      if (dataSize <= rowsPerPage) {
        return rowsPerPage;
      }
    }
    return Math.max(...ROWS_PER_PAGE);
  };

  const countRowsPerPage = (): number | 'all' => {
    if (rowsPerPage === 'dynamic') {
      return getDynamicRowsPerPage();
    } else if (rowsPerPage === 'all') {
      return 'all';
    } else {
      return rowsPerPage;
    }
  };

  const recheckedRowsPerPage = countRowsPerPage();

  const dataOnPage = () =>
    recheckedRowsPerPage === 'all'
      ? data || []
      : data?.slice(
          page * recheckedRowsPerPage,
          page * recheckedRowsPerPage + recheckedRowsPerPage
        ) || [];

  return (
    <>
      <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
        <Table
          size={size}
          sx={{
            ...tableSx,
          }}
        >
          <TableHeadCustom
            sx={{ width: 200 }}
            order={order}
            additionalHeaderRow={additionalHeaderRow}
            orderBy={orderBy}
            headLabels={tableHead}
            rowCount={data?.length}
            numSelected={selected.length}
            onSort={onSort}
            onSelectAllRows={onSelectAllRows}
            addActionColumn={addActionColumn}
            removeFromTabSelection={removeFromTabSelection}
          />

          <TableBody>
            {isLoading &&
              Array.from(Array(rowsPerPage).keys()).map((row: number) => (
                <TableSkeleton
                  key={`row- ${row}`}
                  colSpan={tableHead.length + (addActionColumn ? 1 : 0)}
                />
              ))}
          </TableBody>

          <TableBody>
            {dataOnPage().map((row) => (
              <TableRow
                key={row.id}
                row={row}
                selected={selected.includes(row.id)}
                onSelectRow={onSelectRow && (() => onSelectRow(row.id))}
                handleClickDelete={() =>
                  handleDeleteRow && handleDeleteRow(row.id)
                }
                onEditRow={() => handleEditRow && handleEditRow(row.id)}
              />
            ))}

            {Modals}

            {data && data.length > 0 && (
              <TableEmptyRows
                height={72}
                emptyRows={emptyRows(
                  page,
                  recheckedRowsPerPage === 'all' ? 0 : recheckedRowsPerPage,
                  data?.length || 0
                )}
              />
            )}
            {!isLoading && (
              <NoDataDefaultTable
                isNoData={data?.length === 0}
                dataAvailableBeforeFilter={dataAvailableBeforeFilter}
                colSpan={tableHead.length}
                isError={isError}
                isFiltered={isFiltered}
                isNoDataThisMonth={isNoDataThisMonth}
                customNoDataLabel={customNoDataLabel}
              />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomTablePagination
        page={page}
        onChangePage={onChangePage}
        rowsPerPage={recheckedRowsPerPage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        items={data}
        tableKey={tableKey}
        removeFromTabSelection={removeFromTabSelection}
      />
    </>
  );
}
