import type { FC, PropsWithChildren } from 'react';
import React, { useCallback, useState } from 'react';
import { Box, styled } from '@mui/material';
import { experimental_sx as sx } from '@mui/system';

interface ImageDropZoneProps extends PropsWithChildren {
  upload: (file: File) => Promise<string | undefined>;
  setImageIds: (imageId: string[]) => void;
  accept?: string;
  limit?: number;
}

// eslint-disable-next-line complexity
export function areDragItemsAccepted(
  items: DataTransferItemList,
  accept?: string[],
  limit?: number
): boolean {
  if (limit !== undefined && items.length > limit) {
    return false;
  }

  if (!accept || accept.length === 0) {
    return true;
  }

  const acceptRegex = accept.join('|');
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (item.kind !== 'file' || !item.type.match(acceptRegex)) {
      return false;
    }
  }
  return true;
}

export const ImageUploadDropZone: FC<ImageDropZoneProps> = ({
  children,
  upload,
  setImageIds,
  accept,
  limit,
}) => {
  const [isOver, setIsOver] = useState(false);
  const [isAccepted, setIsAccepted] = useState(true);

  // Memoized event handlers
  const handleDragOver = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      // Note that we only get the dataTransfer.items, not the dataTransfer.files (will be empty before drop), because
      // the latter is considered protected
      // See https://github.com/electron/electron/issues/9840#issuecomment-323146182
      setIsAccepted(
        areDragItemsAccepted(
          event.dataTransfer.items,
          accept?.trim().split(','),
          limit
        )
      );
      event.dataTransfer.dropEffect = isAccepted ? 'copy' : 'none';

      setIsOver(true);
    },
    [limit, accept, isAccepted]
  );

  const handleDragLeave = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      setIsOver(false);
      setIsAccepted(true);
    },
    []
  );

  const handleDrop = useCallback(
    async (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      setIsOver(false);

      if (!isAccepted) {
        return;
      }

      const uploads: Array<Promise<string | undefined>> = [];
      for (let i = 0; i < event.dataTransfer.items.length; i++) {
        const droppedFile = event.dataTransfer.files[i];
        uploads.push(upload(droppedFile));
      }

      try {
        const uploadedIds = await Promise.all(uploads);
        if (uploadedIds.includes(undefined)) {
          // eslint-disable-next-line no-console
          console.error('Upload of a file returned undefined');
        } else {
          // We know there are only strings and no undefined values in the array because we check in the if condition
          setImageIds(uploadedIds as string[]);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error uploading a file:', error);
      }
    },
    [isAccepted, upload, setImageIds]
  );

  return (
    <RootContainer
      isOver={isOver}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      isAccepted={isAccepted}
    >
      {children}
    </RootContainer>
  );
};

interface RootContainerProps {
  isOver: boolean;
  isAccepted: boolean;
}

const RootContainer = styled(Box, {
  shouldForwardProp: (propName: PropertyKey) =>
    propName !== 'isOver' && propName !== 'isAccepted',
})<RootContainerProps>(({ isOver, isAccepted }) =>
  sx({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: isOver ? (isAccepted ? '#F7F8FA' : '#FFF1F1') : 'white',
    zIndex: 1,
  })
);
