import React from 'react';
import type { TypographyProps } from '@mui/material/Typography';
import Typography from '@mui/material/Typography';

/* eslint-disable-next-line */
interface TextProps extends TypographyProps {}

export function Text({ ...props }: TextProps): JSX.Element {
  return <Typography {...props} />;
}
