import type { Breakpoint } from '@mui/material';
import type { MouseEvent } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Iconify,
  LoadingPromiseButtonAdvanced,
  Text,
} from '../index';
import Alert from '@mui/material/Alert';

export interface DeleteDialogProps {
  open: boolean;
  setDeleteDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleDelete: VoidFunction;
  confirmationMessage: string;
  deleteButtonMessage?: string;
  title?: string;
  buttonDisabled?: boolean;
  maxWidth?: false | Breakpoint | undefined;
  alertMessage?: string;
  alertSeverity?: 'error' | 'warning' | 'info' | 'success';
  showAlert?: boolean;
}

// eslint-disable-next-line complexity
export const DeleteDialog: React.FC<DeleteDialogProps> = ({
  open,
  setDeleteDialogOpen,
  handleDelete,
  confirmationMessage,
  deleteButtonMessage,
  title,
  alertMessage,
  alertSeverity,
  showAlert,
  ...props
}) => {
  const { t } = useTranslation();

  const onDelete = React.useCallback(
    async (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      await handleDelete();
    },
    [handleDelete]
  );

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
      {...props}
    >
      <DialogTitle
        sx={{
          pb: 1,
        }}
      >
        <Text
          variant="h5"
          sx={{
            fontWeight: 700,
          }}
        >
          {title ?? t('confirmDeletion')}
        </Text>
      </DialogTitle>
      <DialogContent>
        {confirmationMessage}

        {showAlert && alertSeverity && alertMessage && (
          <Alert severity={alertSeverity} sx={{ mt: 1.5 }}>
            {alertMessage}
          </Alert>
        )}
      </DialogContent>
      <DialogActions
        style={{
          paddingTop: 0,
        }}
      >
        <Button
          autoFocus
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setDeleteDialogOpen(false);
          }}
        >
          {t('cancel')}
        </Button>
        <LoadingPromiseButtonAdvanced
          variant="contained"
          color="error"
          onClick={onDelete}
          size="small"
          startIcon={<Iconify icon={'eva:trash-2-outline'} />}
        >
          {deleteButtonMessage ?? t('actions.delete')}
        </LoadingPromiseButtonAdvanced>
      </DialogActions>
    </Dialog>
  );
};

export interface DeleteDialogWithoutIconProps {
  open: boolean;
  setDeleteDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleDelete: VoidFunction;
  confirmationContent: React.ReactNode;
  deleteButtonMessage?: string;
  title?: string;
  buttonDisabled?: boolean;
  maxWidth?: false | Breakpoint | undefined;
}

export const DeleteDialogWithoutIcon: React.FC<
  DeleteDialogWithoutIconProps
> = ({
  open,
  setDeleteDialogOpen,
  handleDelete,
  confirmationContent,
  deleteButtonMessage,
  title,
  buttonDisabled,
  ...props
}) => {
  const { t } = useTranslation();

  const onDelete = React.useCallback(
    async (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      await handleDelete();
    },
    [handleDelete]
  );

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
      {...props}
    >
      <DialogTitle
        sx={{
          pb: 1,
        }}
      >
        <Text
          variant="h5"
          sx={{
            fontWeight: 700,
          }}
        >
          {title ?? t('confirmDeletion')}
        </Text>
      </DialogTitle>
      <DialogContent>{confirmationContent}</DialogContent>

      <DialogActions
        style={{
          paddingTop: 0,
        }}
      >
        <Button
          autoFocus
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setDeleteDialogOpen(false);
          }}
        >
          {t('cancel')}
        </Button>
        <LoadingPromiseButtonAdvanced
          data-cy="dialog-move-out-button"
          variant="contained"
          color="error"
          onClick={onDelete}
          size="small"
          disabled={buttonDisabled}
        >
          {deleteButtonMessage ?? t('actions.delete')}
        </LoadingPromiseButtonAdvanced>
      </DialogActions>
    </Dialog>
  );
};
