import { m } from 'framer-motion';
import type { ReactNode } from 'react';
import { forwardRef } from 'react';
// @mui
import type { IconButtonProps } from '@mui/material';
import { Box, IconButton } from '@mui/material';
import styled from '@emotion/styled';

// ----------------------------------------------------------------------

const StyledIconButton = styled(IconButton)`
  cursor: pointer;
`;

export const IconButtonAnimate = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ children, size = 'medium', ...other }, ref) => (
    <AnimateWrap size={size}>
      <StyledIconButton ref={ref} {...other}>
        {children}
      </StyledIconButton>
    </AnimateWrap>
  )
);

// ----------------------------------------------------------------------
export default IconButtonAnimate;

interface AnimateWrapProp {
  children: ReactNode;
  size: 'small' | 'medium' | 'large';
}

const varSmall = {
  hover: { scale: 1.1 },
  tap: { scale: 0.95 },
};

const varMedium = {
  hover: { scale: 1.09 },
  tap: { scale: 0.97 },
};

const varLarge = {
  hover: { scale: 1.08 },
  tap: { scale: 0.99 },
};

function AnimateWrap({ size, children }: AnimateWrapProp): JSX.Element {
  const variants = {
    small: varSmall,
    large: varLarge,
    medium: varMedium,
  }[size];

  return (
    <Box
      component={m.div}
      whileTap="tap"
      whileHover="hover"
      variants={variants}
      sx={{
        display: 'inline-flex',
      }}
    >
      {children}
    </Box>
  );
}
