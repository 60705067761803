import { InputAdornment, TextField } from '@mui/material';
import type { FC } from 'react';
import React from 'react';
import { Iconify } from './Iconify';

export interface SearchFieldProps {
  placeholder?: string;
  value: string;
  onChangeValue: (value: string) => void;
  size?: 'small' | 'medium';
}

export const SearchField: FC<SearchFieldProps> = ({
  placeholder,
  value,
  onChangeValue,
  size = 'medium',
}) => {
  return (
    <TextField
      fullWidth
      value={value}
      onChange={(event) => onChangeValue(event.target.value)}
      placeholder={placeholder}
      size={size}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Iconify
              icon={'eva:search-fill'}
              sx={{
                color: 'text.disabled',
                width: 20,
                height: 20,
              }}
            />
          </InputAdornment>
        ),
        endAdornment:
          value.length > 0 ? (
            <InputAdornment position="start">
              <Iconify
                onClick={() => onChangeValue('')}
                icon={'ic:baseline-clear'}
                sx={{
                  color: 'text.disabled',
                  width: 17,
                  height: 17,
                  cursor: 'pointer',
                }}
              />
            </InputAdornment>
          ) : null,
      }}
    />
  );
};
