import type { ComponentType, ReactNode } from 'react';
import type { LabelColor, LabelVariant } from '../../Label';
import type { TableCellProps } from '@mui/material';

export type Row<T> = {
  id: string;
} & T;

type Columns = string;

type Filters = string;

type Alignment = 'left' | 'center' | 'right';

type DefaultSort = 'ASC' | 'DESC';

interface Column<Key extends string, T extends Row<unknown>> {
  key: Key;
  label: string;
  component: ComponentType<{
    row: T;
  }>;
  align?: Alignment;
  /**
   * If the callback is provided, it will enable sorting for this column.
   */
  onSort?: (direction: DefaultSort) => void;
  minWidth?: number;
  maxWidth?: number;
  sx?: TableCellProps['sx'];
}

/**
 * An item in the tab bar
 */
export interface TabItem<T> {
  /// The internal value of the tab
  value: T;
  /// The translation key for the label
  label: string;
  /// The color that the count will be displayed in. Or the color
  /// of the label if no count is provided
  color: LabelColor;
  variant?: LabelVariant;
  /// The count to be displayed in the tab. If a promise is returned,
  /// the tab count will be displayed as loading until the promise resolves
  count?: number | Promise<number>;
}

/**
 * @template filterModel The object containing filter data
 */
export interface Tabs<T> {
  /**
   * The items to be displayed in the tabs
   */
  items: Array<TabItem<T>>;
  updateFilterModel: (selectedItem: T) => void;
  selectedItem?: T;
}

export interface RowAction<T extends Row<unknown>> {
  /**
   * A component that renders a menu item
   */
  component?: ComponentType<{
    row: T;
  }>;

  // Properties if no custom component is necessary

  icon?: ReactNode;
  /// The translation key for the label
  label?: string;
  key?: string;
  errorVariant?: boolean;
  onClick: (row: T) => void | Promise<void>;
}

interface Toolbar {
  primarySelection?: {
    component: React.FC<{
      onChange: (value: any) => void;
    }>;
    onChange: (value: any) => void;
  };
  searchBar?: {
    query: string;
    onQueryChange: (query: string) => void;
    label: string;
  };
}

interface Pagination {
  page: number;
  pageSize: number;
  total: number;
  pageSizeOptions: Array<
    | number
    | {
        value: number;
        label: string;
      }
  >;

  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
}

export type FilterDefinition<FilterKey> =
  | FilterDefinitionBoolean<FilterKey>
  | FilterDefinitionCustom<FilterKey>
  | FilterDefinitionNumberRange<FilterKey>
  | FilterDefinitionDateRange<FilterKey>;

interface FilterDefinitionBase<FilterKey> {
  key: FilterKey;
  label: string;
  isSet: () => boolean;
  toStringRepresentation?: () => string;
  unset?: () => void;
}

interface FilterDefinitionCustom<FilterKey>
  extends FilterDefinitionBase<FilterKey> {
  type: 'custom';
  component: ComponentType;
}

interface FilterDefinitionBoolean<FilterKey>
  extends FilterDefinitionBase<FilterKey> {
  type: 'boolean';
  value: boolean;
  onChange: (value: boolean) => void;
}

export interface FilterDefinitionNumberRange<FilterKey>
  extends FilterDefinitionBase<FilterKey> {
  type: 'numberRange';
  min?: number;
  max?: number;
  range:
    | {
        from?: number;
        to?: number;
      }
    | undefined;
  onChange: (
    range:
      | {
          from?: number;
          to?: number;
        }
      | undefined
  ) => void;
}

export interface FilterDefinitionDateRange<FilterKey>
  extends FilterDefinitionBase<FilterKey> {
  type: 'dateRange';
  min?: Date;
  max?: Date;
  range?: {
    from?: Date;
    to?: Date;
  };
  onChange: (range?: { from?: Date; to?: Date }) => void;
}

interface RowOptions<T extends Row<unknown>> {
  /**
   * Items that should be displayed. If the items differ per row,
   * the callback can be used to generate the items.
   *
   * If the array is empty, no menu will be displayed
   */
  items: Array<RowAction<T>> | ((row: T) => Array<RowAction<T>>);
}

export interface ConfigType<
  T extends Row<unknown>,
  ColumnKeys extends Columns,
  FilterKey extends Filters
> {
  onClickRow?: (row: T) => void | Promise<void>;
  hoverRow?: boolean;
  showAll?: () => void;
  columns: Array<Column<ColumnKeys, T>>;

  /**
   * If defined, each row will have the ic_more menu
   */
  options?: RowOptions<T>;

  /**
   *  The sort column and sort direction for the table. If not provided, no sort indicator is shown.
   *
   *  This should be in sync with the sort in the filter model.
   */
  sortColumn?: {
    column: ColumnKeys;
    direction: DefaultSort;
  };

  /**
   * The component to render when there is no data available. Or a string that will be used as a translation key and shown
   * below the default image.
   */
  noDataAvailable?: ComponentType | string;
  tabs?: Tabs<any>;

  /**
   * The height for the rows when they are empty. Defaults to 60.
   */
  emptyRowHeight?: number;

  /**
   * The toolbar that usually contains a search input
   */
  toolbar?: Toolbar;

  /**
   * The pagination displayed at the bottom of the table
   */
  pagination?: Pagination;

  // Controls available filters - they will also store state to display them as chips
  filters?: {
    items: Array<FilterDefinition<FilterKey>>;
    activeFilters: number;
    noActiveFiltersLabel?: string;
    showActiveFiltersBar?: boolean;
  };
}

export const makeConfig = <
  T extends Row<unknown>,
  ColumnKeys extends Columns,
  FilterKey extends Filters
>(
  config: ConfigType<T, ColumnKeys, FilterKey>
): ConfigType<T, ColumnKeys, FilterKey> => config;
