import React from 'react';
import type { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { default as MuiButton } from '@mui/material/Button';

/* eslint-disable-next-line */
export interface ButtonProps extends MuiButtonProps {}

export function Button({ children, ...props }: ButtonProps): JSX.Element {
  return <MuiButton {...props}>{children}</MuiButton>;
}
