import { useCallback, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

interface QueryParams {
  [key: string]: string | number | boolean;
}

type DebouncedSetQueryParams = (queryParams: QueryParams) => void;

export const useDebouncedSetQueryParams = (): {
  debouncedSetQueryParams: DebouncedSetQueryParams;
} => {
  const [, setSearchParams] = useSearchParams();

  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  const queuedParamsRef = useRef<QueryParams>({});

  const debouncedSetQueryParams = useCallback(
    (queryParams: QueryParams) => {
      queuedParamsRef.current = { ...queuedParamsRef.current, ...queryParams };

      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }

      timeoutIdRef.current = setTimeout(() => {
        const searchParams = new URLSearchParams(window.location.search);
        Object.entries(queuedParamsRef.current).forEach(([key, value]) => {
          searchParams.set(key, value.toString());
        });
        setSearchParams(searchParams, {
          replace: true,
        });

        timeoutIdRef.current = null;
        queuedParamsRef.current = {};
      }, 100);
    },
    [setSearchParams]
  );

  useEffect(() => {
    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, []);

  return { debouncedSetQueryParams };
};
