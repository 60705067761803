import React from 'react';
import type { ModalProps as MuiModalProps } from '@mui/material';
import { styled, Box, Modal as MuiModal } from '@mui/material';

interface ModalProps extends MuiModalProps {
  open: boolean;
  handleClose: (
    event: unknown,
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => void;
}

export function Modal({
  open,
  handleClose,
  children,
}: ModalProps): JSX.Element {
  return (
    <MuiModal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {children}
    </MuiModal>
  );
}

export const ModalInner = styled(Box)({
  position: 'absolute',
  width: 'fit-content',
  height: 'fit-content',
});
