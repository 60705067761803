import type { ReactNode } from 'react';
import { useMemo } from 'react';
// @mui
import { CssBaseline } from '@mui/material';
import type { ThemeOptions } from '@mui/material/styles';
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider as MUIThemeProvider,
} from '@mui/material/styles';
//
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';

// ----------------------------------------------------------------------

interface Props {
  children: ReactNode;
}

export function ThemeProvider({ children }: Props): JSX.Element {
  const themeOptions: ThemeOptions = useMemo(
    () => ({
      typography,
      breakpoints,
      shape: { borderRadius: 4 },
      direction: 'ltr',
      palette,
      shadows,
      customShadows,
      spacing: 8,
    }),
    []
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
