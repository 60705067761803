import { Switch } from '@mui/material';
import * as React from 'react';
import type { SwitchProps } from '@mui/material/Switch';
import type { FormikProps } from 'formik';

interface IProps<T extends string, V> extends SwitchProps {
  name: T;
  formik: FormikProps<V>;
}
export const FSwitch = <
  Field extends string,
  V extends { [key in Field]: unknown }
>({
  name,
  formik,
  ...props
}: IProps<Field, V>): JSX.Element => {
  return (
    <Switch
      name={name}
      value={formik.values[name]}
      checked={formik.values[name] as boolean}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      {...props}
    />
  );
};
