import type { Theme } from '@mui/material/styles';
//
//
import { InputSelectIcon } from './CustomIcons';
import type { Components } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Select(theme: Theme): Pick<Components, 'MuiSelect'> {
  return {
    MuiSelect: {
      defaultProps: {
        IconComponent: InputSelectIcon,
      },
    },
  };
}
