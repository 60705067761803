import type { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import type { ConfigType } from './declarations/type';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ActiveFilterLabel } from './ActiveFilterLabel';
import { Label } from '../Label';

interface ActiveFiltersBarProps {
  filters: ConfigType<any, any, any>['filters'];
  noFiltersLabel?: string;
}

export const ActiveFiltersBar: FC<ActiveFiltersBarProps> = ({
  filters,
  noFiltersLabel,
}) => {
  const { t } = useTranslation();

  const activeFilters = useMemo(
    () => (filters ? filters.items.filter((filter) => filter.isSet()) : []),
    [filters]
  );

  return (
    <Stack direction={'row'} gap={1} sx={{ pb: 1.5, px: 3, gap: 1 }}>
      <Typography variant={'body2'} fontWeight={'bold'}>
        {`${t('common.filter-plural')}:`}
      </Typography>

      {activeFilters.length > 0 ? (
        activeFilters.map((filter) => {
          return <ActiveFilterLabel filter={filter} key={filter.key} />;
        })
      ) : (
        <Label>{noFiltersLabel ?? t('common.no-filters-selected')}</Label>
      )}
    </Stack>
  );
};
