// icons
import type { IconifyIcon } from '@iconify/react';
import { Icon } from '@iconify/react';
// @mui
import type { BoxProps } from '@mui/material';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  icon: IconifyIcon | string;
}

export function Iconify({ icon, ...other }: Props): JSX.Element {
  return <Box component={Icon} icon={icon} {...other} />;
}
