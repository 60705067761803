import type { Theme } from '@mui/material/styles';
import type { Components } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Table(
  theme: Theme
): Pick<
  Components,
  | 'MuiTableRow'
  | 'MuiTableHead'
  | 'MuiTableCell'
  | 'MuiTablePagination'
  | 'MuiTableSortLabel'
  | 'MuiTableBody'
  | 'MuiTableContainer'
> {
  return {
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: theme.palette.action.selected,
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
          },
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          '&:hover': {
            color: theme.palette.primary.light,
          },
          '&.Mui-active': {
            color: theme.palette.primary.light,
            '&:hover': {
              color: theme.palette.primary.light,
            },
            '& .MuiTableSortLabel-icon': { color: theme.palette.primary.light },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
        },
        head: {
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.background.neutral,
          '&:first-of-type': {
            paddingLeft: theme.spacing(4),
          },
          '&:last-of-type': {
            paddingRight: theme.spacing(4),
          },
        },
        stickyHeader: {
          backgroundColor: theme.palette.background.default,
          backgroundImage: `linear-gradient(to bottom, ${theme.palette.background.neutral} 0%, ${theme.palette.background.neutral} 100%)`,
        },
        body: {
          '&:first-of-type': {
            paddingLeft: theme.spacing(4),
          },
          '&:last-of-type': {
            paddingRight: theme.spacing(4),
          },
        },
      },
    },

    MuiTableContainer: {
      styleOverrides: {
        root: {
          width: '100%',
          minWidth: '100%',
          overflowX: 'auto',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          borderTop: `solid 1px ${theme.palette.divider}`,
        },
        selectRoot: {
          fontWeight: 500,
          paddingRight: 0,
          lineHeight: 1.5714285714285714,
        },
        menuItem: {
          fontWeight: 500,
          fontSize: 15,
        },
        select: {
          padding: 0,
          '&:focus': {
            backgroundColor: 'rgba(0,0,0,0)',
          },
        },
      },
    },
  };
}
