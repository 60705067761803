import React, { useState, useCallback } from 'react';
import { useLocation, useMatch } from 'react-router-dom';
// @mui
import { List, Collapse } from '@mui/material';
// type
import type { NavListProps } from '../type';
//
import { NavItemRoot, NavItemSub } from './NavItem';
import { getActive } from '..';

// ----------------------------------------------------------------------

interface NavListRootProps {
  list: NavListProps;
  isCollapse: boolean;
  index: number;
  openMenuIndex: number | undefined;
  setOpenMenuIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
}

export function NavListRoot({
  list,
  isCollapse,
  index,
  openMenuIndex,
  setOpenMenuIndex,
}: NavListRootProps): JSX.Element {
  const { pathname } = useLocation();

  const active = getActive(list.path, pathname);

  React.useEffect(() => {
    if (active) {
      setOpenMenuIndex(index);
    }
  }, [active, index, setOpenMenuIndex]);

  const open = Boolean(index === openMenuIndex);

  const handleClickNavItem = useCallback(() => {
    if (open) {
      setOpenMenuIndex(undefined);
    } else {
      setOpenMenuIndex(index);
    }
  }, [setOpenMenuIndex, open, index]);

  const hasChildren = list.children;

  if (hasChildren) {
    return (
      <>
        <NavItemRoot
          item={list}
          isCollapse={isCollapse}
          active={active}
          open={open}
          onOpen={handleClickNavItem}
        />

        {!isCollapse && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding sx={{ p: 0 }}>
              {(list.children || []).map((item) => (
                <NavListSub key={item.title} list={item} />
              ))}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return <NavItemRoot item={list} active={active} isCollapse={isCollapse} />;
}

// ----------------------------------------------------------------------

interface NavListSubProps {
  list: NavListProps;
}

function NavListSub({ list }: NavListSubProps): JSX.Element {
  const { pathname } = useLocation();

  const active = useMatch(list.path) !== null;

  const [open, setOpen] = useState(active);

  const hasChildren = list.children;

  if (hasChildren) {
    return (
      <>
        <NavItemSub
          item={list}
          onOpen={() => setOpen(!open)}
          open={open}
          active={active}
        />

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: 0 }}>
            {(list.children || []).map((item) => (
              <NavItemSub
                key={item.title}
                item={item}
                active={getActive(item.path, pathname)}
              />
            ))}
          </List>
        </Collapse>
      </>
    );
  }

  return <NavItemSub item={list} active={active} />;
}
