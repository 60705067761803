import type { Theme } from '@mui/material/styles';
import type { Components } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Input(
  theme: Theme
): Pick<
  Components,
  | 'MuiInputBase'
  | 'MuiInput'
  | 'MuiFilledInput'
  | 'MuiOutlinedInput'
  | 'MuiInputAdornment'
  | 'MuiInputLabel'
  | 'MuiTextField'
> {
  return {
    MuiInputBase: {
      styleOverrides: {
        input: {
          height: '1.4em',
        },
        root: {
          backgroundColor: theme.palette.background.default,
          fontSize: 16,
          fontWeight: 600,
          textTransform: 'capitalize',
          padding: '0px 7px',
          '&.Mui-focused': {
            borderColor: theme.palette.grey['400'],
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          input: {
            '&:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 100px white inset',
              WebkitTextFillColor: theme.palette.primary.main,
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: {
          backgroundColor: theme.palette.background.default,
          padding: '0px 4px',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottomColor: theme.palette.grey[500_56],
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[500_12 ?? null],
          '&:hover': {
            backgroundColor: theme.palette.grey[500_16],
          },
          '&.Mui-focused': {
            backgroundColor: theme.palette.action.focus,
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.action.disabledBackground,
          },
        },
        underline: {
          '&:before': {
            borderBottomColor: theme.palette.grey[500_56],
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          border: `2px solid ${theme.palette.grey[300]}`,
        },

        notchedOutline: {
          border: 'none',
          outlineStyle: 'none',
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          '>.MuiTypography-root': {
            color: theme.palette.text.disabled,
            fontWeight: 800,
          },
        },
      },
    },
  };
}
