import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import type { FC } from 'react';
import * as React from 'react';
import { useState } from 'react';
import { useFormatting } from '@pflegenavi/frontend/localization';
import { useFormik } from 'formik';
import { Card, Stack, Typography } from '@mui/material';
import { OneInputSubmitForm } from './OneInputSubmitForm';
import { usePrompt } from './hooks/usePrompt';
import { useTranslation } from 'react-i18next';

const RootStyleOneNumberInputSubmitCard = styled('div')(
  ({ theme }: { theme: Theme }) => ({
    minWidth: 120,
    minHeight: 120,
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: '50%',
    padding: theme.spacing(1),
    border: `1px dashed ${theme.palette.grey[500_32]}`,
  })
);

const DropZoneStyle = styled('div')({
  zIndex: 0,
  width: '100%',
  height: '100%',
  outline: 'none',
  display: 'flex',
  overflow: 'hidden',
  borderRadius: '50%',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  '& > *': { width: '100%', height: '100%' },
});

const PlaceholderStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.neutral,
}));

interface OneNumberInputSubmitCardProps {
  amount?: number;
  isCurrency: boolean;
  update: (value: any) => void;
  valueKey: string;
  title: string;
  placeholder: string;
  buttonTitle: string;
  'data-cy'?: string;
  'data-cy-label'?: string;
  inputSize?: number;
}

export const OneNumberInputSubmitCard: FC<OneNumberInputSubmitCardProps> = ({
  amount,
  isCurrency = true,
  valueKey,
  update,
  buttonTitle,
  placeholder,
  title,
  inputSize,
  ...props
}) => {
  const { fCurrency } = useFormatting();
  const { t } = useTranslation();

  const [submitting, setSubmitting] = useState(false);

  const onSubmit = async () => {
    setSubmitting(true);
    if (formik.values[valueKey] !== '') {
      update(formik.values[valueKey] ?? 0);
    }
    await formik.setFieldValue(valueKey, '');
    setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: {
      [valueKey]: '',
    },
    onSubmit,
  });

  usePrompt(t('confirm-page-leave'), formik.dirty);

  return (
    <Card sx={{ p: 3, flex: 2 }} data-cy={props['data-cy']}>
      <Stack direction="column" gap={3}>
        <Typography
          variant="h6"
          sx={{
            fontSize: '1.125rem',
            fontWeight: 700,
          }}
        >
          {title}
        </Typography>

        <Stack direction="row" gap={3}>
          <RootStyleOneNumberInputSubmitCard>
            <DropZoneStyle>
              <PlaceholderStyle className="placeholder">
                <Typography variant="h5" data-cy={props['data-cy-label']}>
                  {isCurrency
                    ? fCurrency(amount ?? 0, { minimumFractionDigits: 0 })
                    : amount}
                </Typography>
              </PlaceholderStyle>
            </DropZoneStyle>
          </RootStyleOneNumberInputSubmitCard>

          <OneInputSubmitForm
            formik={formik}
            formikField={valueKey}
            onSubmit={onSubmit}
            submitting={submitting}
            buttonLabel={buttonTitle}
            inputLabel={placeholder}
            allowDecimal={false}
            inputSize={inputSize}
          />
        </Stack>
      </Stack>
    </Card>
  );
};
