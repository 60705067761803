import type { Theme } from '@mui/material/styles';
import type { Components } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Button(theme: Theme): Pick<Components, 'MuiButton'> {
  return {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          textTransform: 'none',
          // padding: '8px 40px',
          paddingY: 3,
          paddingX: 12,
          fontSize: 14,
          borderRadius: 8,
        },
        containedPrimary: {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
          '&:hover, &.MuiSelected, &.Mui-focusVisible': {
            opacity: 0.9,
          },
        },
      },
    },
  };
}
