export function getNameInitials(name: string): string {
  // If the name is an email address, strip the domain.
  if (name.indexOf('@') !== -1) {
    name = name.substring(0, name.indexOf('@'));
  }

  // Check each token if it is in the name and return the first one.
  const [token, hasToken] = [' ', '.']
    .map<[string, boolean]>((t) => [t, name.indexOf(t) !== -1])
    .filter(([, hasToken]) => hasToken)
    .shift() ?? ['', false];

  return (
    name.substring(0, 1) +
    (hasToken ? name.charAt(name.lastIndexOf(token) + 1) : '')
  ).toUpperCase();
}
