import { styled } from '@mui/material/styles';

export const TabsWrapperStyle = styled('div')(({ theme }) => ({
  zIndex: 9,
  bottom: 0,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  paddingTop: 50,
  backgroundColor: theme.palette.background.paper,
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    paddingTop: 0,
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(3),
  },
}));
