import type { Theme } from '@mui/material/styles';
import type { Components } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Skeleton(
  theme: Theme
): Pick<Components, 'MuiSkeleton'> {
  return {
    MuiSkeleton: {
      defaultProps: {
        animation: 'wave',
      },

      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.neutral,
        },
      },
    },
  };
}
