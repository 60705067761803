/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Fuse from 'fuse.js';

export interface IFuseOptionsExtended<T> extends Fuse.IFuseOptions<T> {
  getFormattedSearchValue?: (
    obj: T,
    path: string | string[]
  ) => readonly string[] | string | void | undefined;
}

const commonFuseFn = (obj: any, path: string | string[]) => {
  const getValue = (innerObj: object | string, indx: number): string => {
    if (innerObj === undefined || indx >= path.length) {
      return typeof innerObj === 'string' ? innerObj : '';
    }
    // @ts-expect-error index types unknown
    return getValue(innerObj[path[indx]], ++indx);
  };
  return getValue(obj, 0);
};

export function applySortFilter<
  TData extends { type: TFilterStatus },
  TFilterStatus
>({
  dataFiltered,
  comparator,
  filterTerms,
  filterStatus,
  fuseOptions,
}: {
  dataFiltered: TData[];
  comparator: (a: any, b: any) => number;
  filterTerms?: string;
  filterStatus: TFilterStatus | 'all';
  fuseOptions: IFuseOptionsExtended<TData>;
}) {
  const stabilizedThis = dataFiltered.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  dataFiltered = stabilizedThis.map((el) => el[0]);

  if (fuseOptions.getFormattedSearchValue) {
    fuseOptions.getFn = (obj, path) => {
      return (
        (fuseOptions.getFormattedSearchValue &&
          fuseOptions.getFormattedSearchValue(obj, path)) ??
        commonFuseFn(obj, path)
      );
    };
  }

  if (filterTerms) {
    const fuse = new Fuse(dataFiltered, fuseOptions);
    const rawFilteredData = fuse.search(filterTerms);
    dataFiltered = rawFilteredData.map(({ item }) => item);
  }

  let dataFilteredAfterStatus = dataFiltered;
  if (filterStatus !== 'all') {
    dataFilteredAfterStatus = dataFilteredAfterStatus.filter(
      (item) => item.type === filterStatus
    );
  }

  return { dataFiltered, dataFilteredAfterStatus };
}
