import type { FC } from 'react';
import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Tooltip, Typography } from '@mui/material';
import type { TypographyProps } from '@mui/material/Typography';
import type { TooltipProps } from '@mui/material/Tooltip';

interface TruncatedTooltipTextProps extends TypographyProps {
  text: string;
  maxWidth?: number;
  tooltipPlacement?: TooltipProps['placement'];
  noTooltip?: boolean;
}

export const TruncatedTooltipText: FC<TruncatedTooltipTextProps> = ({
  text,
  maxWidth = 220,
  tooltipPlacement = 'top-start',
  noTooltip,
  ...props
}) => {
  const ellipsisStyles = useMemo(
    () => ({
      maxWidth,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
    [maxWidth]
  );

  const textRef = useRef<HTMLParagraphElement>(null);

  const [showTextTooltip, setShowTextTooltip] = useState(false);

  useLayoutEffect(() => {
    if (
      textRef.current &&
      textRef.current.offsetWidth < textRef.current.scrollWidth
    ) {
      setShowTextTooltip(true);
    }
  }, []);

  if (noTooltip) {
    return (
      <Typography
        ref={textRef}
        lineHeight={1}
        {...props}
        sx={
          props.sx
            ? {
                ...props.sx,
                ...ellipsisStyles,
              }
            : ellipsisStyles
        }
      >
        {text}
      </Typography>
    );
  }

  return (
    <Tooltip
      arrow
      placement={tooltipPlacement}
      title={showTextTooltip ? text : undefined}
    >
      <Typography
        ref={textRef}
        lineHeight={1}
        {...props}
        sx={
          props.sx
            ? {
                ...props.sx,
                ...ellipsisStyles,
              }
            : ellipsisStyles
        }
      >
        {text}
      </Typography>
    </Tooltip>
  );
};
