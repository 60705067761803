import type { FC } from 'react';
import { Box, styled } from '@mui/material';
import { experimental_sx as sx } from '@mui/system';
import { logosMapping } from './utils/logoMapping';

interface TransactionTypeLogoProps {
  size?: number;
  shortName?: string;
  logoType?: string;
  additionalSubIcon?: string;
  cornered?: boolean;
}

export const TransactionTypeLogo: FC<TransactionTypeLogoProps> = ({
  logoType,
  size = 40,
  additionalSubIcon,
  cornered = false,
}) => {
  const SelectedIcon =
    logosMapping.get(logoType ?? '_unknown') ?? logosMapping.get('_unknown');
  return (
    <LogoContainer
      sx={{
        flexShrink: 0,
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: cornered ? `${size / 4}px` : `${size / 2}px`,
      }}
    >
      <img alt="" src={SelectedIcon} width={size / 2} height={size / 2} />
      {additionalSubIcon && (
        <AdditionalIcon
          alt=""
          src={additionalSubIcon}
          width={13}
          height={13}
          cornered={cornered}
        />
      )}
    </LogoContainer>
  );
};

const LogoContainer = styled(Box)(
  sx({
    bgcolor: 'grey.300',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    p: 1,
    position: 'relative',
  })
);

interface AdditionalIconProps {
  cornered: boolean;
}

const AdditionalIcon = styled('img')<AdditionalIconProps>(({ cornered }) =>
  sx({
    right: cornered ? -5 : 1,
    bottom: 0,
    position: 'absolute',
  })
);
