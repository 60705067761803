// @mui
import { styled, TableCell, TableRow } from '@mui/material';
import { Box } from '@mui/system';
import type { FC, ReactNode } from 'react';

interface NoDataRowProps {
  children: ReactNode;
  text?: string;
  img?: string;
  colSpan: number;
}

const InnerTableCell = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  padding-top: 25px;
`;

const NoDataRow: FC<NoDataRowProps> = ({ colSpan, children }) => {
  return (
    <TableRow
      sx={{
        minHeight: 350,
      }}
    >
      <TableCell colSpan={colSpan}>
        <InnerTableCell>{children}</InnerTableCell>
      </TableCell>
    </TableRow>
  );
};

// ----------------------------------------------------------------------

interface Props {
  children?: ReactNode;
  isNotFound: boolean;
  colSpan: number;
}

const TableNoData: FC<Props> = ({ isNotFound, colSpan, children }) => {
  if (isNotFound) {
    return <NoDataRow colSpan={colSpan}>{children}</NoDataRow>;
  }
  return (
    <TableRow>
      <TableCell colSpan={colSpan} sx={{ p: 0 }} />
    </TableRow>
  );
};

export default TableNoData;
