import { styled, TableHead } from '@mui/material';

export const TableHeadWithRadiusCorners = styled(TableHead)(({ theme }) => ({
  '& .MuiTableCell-root:first-of-type': {
    borderTopLeftRadius: Number(theme.shape.borderRadius) * 2,
    borderBottomLeftRadius: Number(theme.shape.borderRadius) * 2,
  },
  '& .MuiTableCell-root:last-of-type': {
    borderTopRightRadius: Number(theme.shape.borderRadius) * 2,
    borderBottomRightRadius: Number(theme.shape.borderRadius) * 2,
  },
}));
