import { Typography } from '@mui/material';
import type { FC, PropsWithChildren } from 'react';
import React from 'react';

export const TableFillerTextStyled: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Typography
      variant="inherit"
      fontWeight="700"
      sx={{ mb: 2, textAlign: 'center' }}
    >
      {children}
    </Typography>
  );
};
