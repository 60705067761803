import { ResidentState } from '@pflegenavi/shared/api';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from './Label';

interface ResidentStateLabelProps {
  state: ResidentState;
}

export const ResidentStateLabel: FC<ResidentStateLabelProps> = ({
  state,
  ...other
}) => {
  const { t } = useTranslation();
  const balanceColor =
    state === ResidentState.archived
      ? 'default'
      : state === ResidentState.active
      ? 'success'
      : 'info';

  const balanceLabel =
    state === ResidentState.archived
      ? t('statusLabel.Inactive')
      : state === ResidentState.active
      ? t('statusLabel.Active')
      : t('statusLabel.AwaitingSettlement');

  return (
    <Label variant="ghost" color={balanceColor}>
      {balanceLabel}
    </Label>
  );
};
