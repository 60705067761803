import type { Theme } from '@mui/material/styles';
import type { Components } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function ControlLabel(
  theme: Theme
): Pick<
  Components,
  'MuiFormControlLabel' | 'MuiFormHelperText' | 'MuiFormLabel'
> {
  return {
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          ...theme.typography.body2,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: theme.spacing(1),
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,
          lineHeight: '1.70em',
          padding: '0 7px',
        },
      },
    },
  };
}
