import type { FC } from 'react';
import { InputAdornment, Stack, TextField } from '@mui/material';
import { Iconify } from '../Iconify';

export interface DefaultTableToolbarProps {
  searchPrompt: string;
  filterTerms: string;
  onFilterTerms: (value: string) => void;
}
export const DefaultTableToolbar: FC<DefaultTableToolbarProps> = ({
  filterTerms,
  onFilterTerms,
  searchPrompt,
}) => {
  return (
    <Stack direction="row" alignItems="center" sx={{ py: 2.5, px: 3, gap: 1 }}>
      <TextField
        sx={{ flex: 1 }}
        value={filterTerms}
        onChange={(event) => onFilterTerms(event.target.value)}
        placeholder={searchPrompt}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon={'eva:search-fill'}
                sx={{ color: 'text.disabled', width: 20, height: 20 }}
              />
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
};
