import { useState, useEffect } from 'react';
import type { Dispatch, SetStateAction } from 'react';

export function useResetSearchInput(
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  tabFilterValue: any
): [string, Dispatch<SetStateAction<string>>] {
  const [filterTerms, setFilterTerms] = useState('');

  useEffect(() => {
    setFilterTerms('');
  }, [tabFilterValue]);

  return [filterTerms, setFilterTerms];
}
