import type { VFC } from 'react';
import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import NotFoundImage from '../images/404.svg';
import { Button } from '../../index';
import { ErrorContainer } from './ErrorContainer';

export const PageNotFound: VFC<{ handleGoHomeClick: VoidFunction }> = ({
  handleGoHomeClick,
}) => {
  const { t } = useTranslation();

  return (
    <ErrorContainer>
      <img
        alt={t('errors.something-went-wrong')}
        src={NotFoundImage}
        style={{ marginBottom: 35 }}
      />
      <Typography variant="inherit" fontWeight="700" sx={{ mb: 2 }}>
        {t('errors.something-went-wrong')}
      </Typography>
      <Button variant="contained" autoFocus onClick={handleGoHomeClick}>
        {t('errors.go-to-home')}
      </Button>
    </ErrorContainer>
  );
};
