import type { FC, PropsWithChildren } from 'react';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import { cssStyles } from '../index';

export const CommonProfileCover: FC<PropsWithChildren> = ({ children }) => {
  return (
    <RootStyle>
      <InfoStyle>{children}</InfoStyle>
    </RootStyle>
  );
};

const RootStyle = styled('div')(({ theme }) => ({
  '&:before': {
    ...cssStyles().bgBlur({ blur: 2, color: theme.palette.primary.lighter }),
    top: 0,
    zIndex: 9,
    content: "''",
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
}));

const InfoStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  zIndex: 99,
  position: 'absolute',
  marginTop: theme.spacing(3),
  top: -10,
  [theme.breakpoints.up('md')]: {
    right: 'auto',
    display: 'flex',
    alignItems: 'center',
    left: theme.spacing(3),
    bottom: theme.spacing(3),
  },
}));
