// @mui
import type { TableRowProps } from '@mui/material';
import { TableRow, TableCell, Skeleton } from '@mui/material';

// ----------------------------------------------------------------------

interface Props extends TableRowProps {
  colSpan: number;
}

export default function TableSkeleton({
  colSpan,
  ...other
}: Props): JSX.Element {
  return (
    <TableRow {...other}>
      <TableCell colSpan={colSpan}>
        <Skeleton variant="text" height={40} />
      </TableCell>
    </TableRow>
  );
}
