import React from 'react';

import { useTranslation } from 'react-i18next';

import NoRecordsSvg from '../images/undraw_no_records.svg';
import NoSearchResults from '../images/undraw_no_search_results.svg';
import ServerNotReached from '../images/Server maintenance.svg';
import TableNoDataWithImage from './TableNoDataWithImage';

interface Props {
  isNoData: boolean;
  isNoDataThisMonth: boolean;
  dataAvailableBeforeFilter: boolean;
  isFiltered: boolean;
  isError: boolean;
  colSpan: number;
  display: boolean;
  customNoDataLabel?: string;
}

// eslint-disable-next-line complexity
export const NoDataDefaultTable: React.FC<Partial<Props>> = ({
  isNoData = false,
  isNoDataThisMonth = false,
  dataAvailableBeforeFilter = false,
  isFiltered = false,
  isError = false,
  colSpan = 1,
  display = true,
  customNoDataLabel,
}) => {
  const { t } = useTranslation();

  if (!display) {
    return null;
  }

  const getTable = ({
    height = 220,
    ...props
  }: {
    height?: number;
    text: string;
    image: string;
  }): React.ReactElement => {
    return (
      <TableNoDataWithImage height={height} colSpan={colSpan} {...props} />
    );
  };

  if (isNoDataThisMonth) {
    return getTable({
      text: customNoDataLabel ?? t('table.no-data-this-month'),
      image: NoRecordsSvg,
    });
  }

  if (!isError && !dataAvailableBeforeFilter) {
    return getTable({ text: t('table.no-data'), image: NoRecordsSvg });
  }

  if (!isError && dataAvailableBeforeFilter && !isFiltered && isNoData) {
    return getTable({ text: t('table.no-data'), image: NoRecordsSvg });
  }

  if (!isError && dataAvailableBeforeFilter && isFiltered && isNoData) {
    return getTable({ text: t('table.no-results'), image: NoSearchResults });
  }

  if (isError && isNoData) {
    return getTable({
      text: t('table.no-connection'),
      image: ServerNotReached,
      height: 200,
    });
  }

  return null;
};
