// @mui
import type { IconButtonProps } from '@mui/material';
import { IconButton } from '@mui/material';
//
import { Iconify } from '../Iconify';
import { MenuPopover } from '../MenuPopover';

// ----------------------------------------------------------------------

interface Props {
  actions: React.ReactNode;
  open?: HTMLElement | null;
  onClose?: (event: React.MouseEvent<HTMLElement>) => void;
  onOpen?: (event: React.MouseEvent<HTMLElement>) => void;
  removeFromTabSelection?: boolean;
  iconButtonSx?: IconButtonProps['sx'];
  iconButtonProps?: IconButtonProps;
}

export default function TableMoreMenu({
  actions,
  open,
  onClose,
  onOpen,
  removeFromTabSelection = false,
  iconButtonSx,
  iconButtonProps,
}: Props): JSX.Element {
  return (
    <>
      <IconButton
        data-testid="more-menu"
        onClick={onOpen}
        tabIndex={removeFromTabSelection ? -1 : 0}
        {...iconButtonProps}
        sx={iconButtonSx}
      >
        <Iconify icon={'eva:more-vertical-fill'} width={20} height={20} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        arrow="right-top"
        sx={{
          mt: -1,
          width: 'fit-content',
          minWidth: 200,
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75,
            '& svg': {
              mr: 2,
              width: 20,
              height: 20,
            },
          },
        }}
      >
        {actions}
      </MenuPopover>
    </>
  );
}
