import { Box, IconButton, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Iconify } from './Iconify';
import type { FC } from 'react';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export enum ChangeCashMode {
  Deposit = 'deposit',
  Withdraw = 'withdraw',
  Adjust = 'adjust',
}

interface IncrementerProps {
  available?: number;
  quantity: number;
  onIncrease: () => void;
  onDecrease: () => void;
  onOverride: (newValue: number) => void;
  mode: ChangeCashMode;
  autoFocus?: boolean;
}

// eslint-disable-next-line complexity
export const Incrementer: FC<IncrementerProps> = ({
  available,
  quantity,
  onIncrease,
  onDecrease,
  onOverride,
  mode,
  autoFocus,
}) => {
  const { t } = useTranslation();

  const isAdjustMode = mode === ChangeCashMode.Adjust;

  const handleDecrease = () => {
    onDecrease();
  };
  const disableDecrease = quantity < 1;

  const handleIncrease = () => {
    onIncrease();
  };
  const disableIncrease =
    mode === ChangeCashMode.Withdraw &&
    available !== undefined &&
    available <= quantity;

  const inputMaxValue =
    mode === ChangeCashMode.Withdraw ? available : undefined;
  // eslint-disable-next-line complexity
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let eventValue = e.target.value;

    // Disallow leading zeros
    if (/^0\d+/.test(eventValue)) {
      eventValue = eventValue.replace(/^0+/, '');
    }

    if (eventValue.length > 0) {
      const value = parseInt(eventValue.slice(0, 3));
      if (value >= 0) {
        if (inputMaxValue && value > inputMaxValue) {
          return;
        } else {
          onOverride(value);
        }
      }
    } else {
      onOverride(0);
    }
  };
  const disableInput = useMemo(() => {
    if (mode === ChangeCashMode.Withdraw && available !== undefined) {
      return available < 1;
    }
    return false;
    // TODO: available & mode missing
    // eslint-disable-next-line @grncdr/react-hooks/exhaustive-deps
  }, []);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const inputValue =
    inputRef?.current === document.activeElement &&
    quantity === 0 &&
    inputRef?.current?.value.length === 0
      ? ''
      : quantity.toString();

  const handleNonNumericKey = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const allowedChars =
      quantity === 0 && inputValue !== '' ? '123456789' : '0123456789';

    function contains(stringValue: string, charValue: string) {
      return stringValue.indexOf(charValue) > -1;
    }

    const invalidKey = e.key.length === 1 && !contains(allowedChars, e.key);
    if (invalidKey) {
      e.preventDefault();
    }
  };

  return (
    <Box sx={{ width: 110, textAlign: 'right' }}>
      <IncrementerStyle>
        <IconButton
          size="small"
          color="inherit"
          onClick={handleDecrease}
          disabled={disableDecrease}
          tabIndex={-1}
        >
          <Iconify icon={'eva:minus-fill'} width={16} height={16} />
        </IconButton>
        <TextField
          value={inputValue}
          onChange={handleInputChange}
          disabled={disableInput}
          onFocus={(event) => {
            event.target.select();
          }}
          onBlur={() => {
            if (quantity === 0) {
              onOverride(0);
            }
          }}
          onKeyPress={handleNonNumericKey}
          InputProps={{
            type: 'number',
            disableUnderline: true,
            inputProps: {
              min: 0,
              max: inputMaxValue,
              ref: inputRef,
            },
          }}
          variant="standard"
          sx={{
            '& input': {
              textAlign: 'center',
            },
          }}
          autoFocus={autoFocus}
        />
        <IconButton
          size="small"
          color="inherit"
          onClick={handleIncrease}
          disabled={disableIncrease}
          tabIndex={-1}
        >
          <Iconify icon={'eva:plus-fill'} width={16} height={16} />
        </IconButton>
      </IncrementerStyle>
      {available !== undefined && (
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          {t(`cashManagement.${isAdjustMode ? 'current' : 'available'}`)}{' '}
          {available}
        </Typography>
      )}
    </Box>
  );
};

const IncrementerStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(0.5),
  padding: theme.spacing(0.5, 0.75),
  borderRadius: theme.shape.borderRadius,
  border: `solid 1px ${theme.palette.grey[500_32]}`,
}));
