import type { Components, Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Chip(theme: Theme): Pick<Components<Theme>, 'MuiChip'> {
  return {
    MuiChip: {
      styleOverrides: {
        outlined: {
          borderColor: theme.palette.grey[500_32],
          '&.MuiChip-colorPrimary': {
            borderColor: theme.palette.primary.main,
          },
          '&.MuiChip-colorSecondary': {
            borderColor: theme.palette.secondary.main,
          },
        },
        filled: {
          '&.Mui-focusVisible': {
            opacity: 0.8,
          },
        },
      },
    },
  };
}
