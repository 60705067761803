import type { VFC } from 'react';
import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import SomethingWentWrongImage from '../images/SomethingWentWrong.svg';
import { Button } from '../Button';
import { useTranslation } from 'react-i18next';
import { ErrorContainer } from './ErrorContainer';
import { useTheme } from '@mui/material/styles';

export const SomethingWentWrong: VFC<{
  handleGoHomeClick: VoidFunction;
  supportEmail: string;
  errorMessage: string;
}> = ({ handleGoHomeClick, supportEmail, errorMessage }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    // @ts-expect-error Beacon is untyped
    if (!window.Beacon) {
      return;
    }

    // @ts-expect-error Beacon is untyped
    window.Beacon('open');
    // @ts-expect-error Beacon is untyped
    window.Beacon('session-data', {
      error: errorMessage,
    });
    // @ts-expect-error Beacon is untyped
    window.Beacon('navigate', '/ask/message/');
  }, [t, errorMessage]);

  return (
    <ErrorContainer>
      <img
        alt={t('errors.something-went-wrong')}
        src={SomethingWentWrongImage}
        style={{ marginBottom: 44 }}
      />
      <Typography variant="inherit" fontWeight="700" sx={{ mb: 3 }}>
        {t('errors.something-went-wrong')}
      </Typography>
      <Button
        sx={{
          mb: 3,
        }}
        variant="contained"
        autoFocus
        onClick={handleGoHomeClick}
      >
        {t('errors.go-to-home')}
      </Button>
      <Typography
        variant="subtitle2"
        sx={{
          color: theme.palette.grey['600'],
        }}
      >
        {t('errors.for-assistance')}{' '}
        <a
          style={{
            color: theme.palette.grey['600'],
          }}
          href={`mailto:${supportEmail}`}
        >
          {t('errors.contact-here')}
        </a>
      </Typography>
    </ErrorContainer>
  );
};
