import type { Theme } from '@mui/material/styles';
import type { Components } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Breadcrumbs(
  theme: Theme
): Pick<Components, 'MuiBreadcrumbs'> {
  return {
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: {
          marginLeft: theme.spacing(2),
          marginRight: theme.spacing(2),
        },
      },
    },
  };
}
