import type { FC } from 'react';
import type { BoxProps } from '@mui/system';
import { Box } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';
import { memo } from 'react';

interface LoadingContainerProps extends BoxProps {
  fullWidth?: boolean;
}

export const LoadingContainer: FC<LoadingContainerProps> = memo(
  ({ fullWidth, ...props }) => {
    return (
      <Box
        sx={{
          width: fullWidth ? '100%' : 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        {...props}
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }
);
