import * as React from 'react';
import { useEffect } from 'react';
import { TextField } from '@mui/material';
import { useFormattedIban } from './hooks/useFormattedIban';

interface IbanInputProps {
  value: string;
  setValue: (value: string) => void;
  error: string | undefined;
  disabled?: boolean;
}

export const IbanInput: React.FC<IbanInputProps> = ({
  value,
  setValue,
  error,
  disabled,
}) => {
  const { spacedIban, formatIban } = useFormattedIban();

  const handleIban = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value;
    formatIban(value.replace(/\s/g, ''));
    setValue(value);
  };

  useEffect(() => {
    formatIban(value);
  }, [formatIban, value]);

  return (
    <TextField
      id="iban"
      name="iban"
      label="IBAN"
      placeholder="IBAN"
      fullWidth
      InputLabelProps={{ shrink: true }}
      value={spacedIban}
      onChange={handleIban}
      error={Boolean(error)}
      helperText={error}
      disabled={disabled}
    />
  );
};
