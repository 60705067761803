import React from 'react';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import type { HeadCell } from './TableHeadCustom';
import TableSkeleton from './TableSkeleton';
import { NoDataDefaultTable } from './NoDataDefaultTable';

// ----------------------------------------------------------------------

export interface SimpleTableProps<
  T extends { id: string },
  TColumn extends string
> {
  data?: T[];
  isError: boolean;
  isLoading: boolean;
  headLabels: Array<HeadCell<TColumn>>;
  TableRowComponent: React.ComponentType<{
    row: T;
    disableRows?: boolean;
    onRowSelected: (itemId: string) => void;
    onToggleProrate: (itemId: string) => void;
  }>;
  dataAvailableBeforeFilter: boolean;
  disableRows?: boolean;
  onRowSelected: (itemId: string) => void;
  onToggleProrate: (itemId: string) => void;
  allSelected: boolean;
  selectAll: VoidFunction;
  unselectAll: VoidFunction;
}

export function SimpleTable<T extends { id: string }, TColumn extends string>({
  data,
  isError,
  isLoading,
  headLabels,
  dataAvailableBeforeFilter,
  TableRowComponent,
  disableRows,
  onRowSelected,
  allSelected,
  selectAll,
  unselectAll,
  onToggleProrate,
}: SimpleTableProps<T, TColumn>): JSX.Element {
  return (
    <TableContainer sx={{ minWidth: '100%', position: 'relative' }}>
      <Table size="small">
        <TableBody>
          <TableRow
            sx={{
              '& .MuiTableCell-root': {
                borderBottom: '1px lightGrey solid',
              },
            }}
          >
            <TableCell padding="checkbox">
              <Checkbox
                checked={allSelected}
                onChange={() => (allSelected ? unselectAll() : selectAll())}
                color="primary"
                disableFocusRipple
                disableRipple
                disableTouchRipple
              />
            </TableCell>
            {headLabels.map((headCell) => {
              return (
                <TableCell
                  key={headCell.id}
                  align={headCell.align || 'left'}
                  sx={{
                    width: headCell.width,
                    minWidth: headCell.minWidth,
                    fontWeight: 700,
                  }}
                >
                  {headCell.label}
                </TableCell>
              );
            })}
          </TableRow>
        </TableBody>
        <TableBody>
          {isLoading &&
            Array.from(Array(4).keys()).map((row: number) => (
              <TableSkeleton key={`row- ${row}`} colSpan={headLabels.length} />
            ))}
        </TableBody>

        <TableBody>
          {data?.map((row) => (
            <TableRowComponent
              key={row.id}
              row={row}
              disableRows={disableRows}
              onRowSelected={onRowSelected}
              onToggleProrate={onToggleProrate}
            />
          ))}

          {!isLoading && (
            <NoDataDefaultTable
              isNoData={data?.length === 0}
              dataAvailableBeforeFilter={dataAvailableBeforeFilter}
              colSpan={headLabels.length}
              isError={isError}
            />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
