import { Stack, Tab, Tabs } from '@mui/material';
import type { LabelColor } from '../Label';
import { Label } from '../Label';
import type { PropsWithChildren } from 'react';

export interface TableTab<T extends string> {
  value: T;
  count: number;
  color: LabelColor;
  label: string;
}

export interface TableTabsProps<T extends string> {
  value: T;

  setValue(value: T): void;

  tabs: Array<TableTab<T>>;
}

export function TableTabs<T extends string>({
  value,
  setValue,
  tabs,
  children,
}: PropsWithChildren<TableTabsProps<T>>): JSX.Element {
  return (
    <Tabs
      allowScrollButtonsMobile
      variant="scrollable"
      scrollButtons="auto"
      value={value}
      onChange={(event, value: T) => setValue(value)}
      sx={{
        px: 2,
        bgcolor: 'background.neutral',
      }}
    >
      {tabs.map((tab) => (
        <Tab
          disableRipple
          key={tab.value}
          value={tab.value}
          label={
            <Stack spacing={1} direction="row" alignItems="center">
              <div>{tab.label}</div>
              <Label color={tab.color} variant="ghost">
                {tab.count}
              </Label>
            </Stack>
          }
        />
      ))}
      {children}
    </Tabs>
  );
}
