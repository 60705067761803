import type { FC, MouseEvent } from 'react';
import { useCallback, useEffect, useState } from 'react';
import type { LoadingButtonProps } from '@mui/lab';
import { LoadingButton } from '@mui/lab';

interface LoadingPromiseButtonProps
  extends Omit<LoadingButtonProps, 'loading'> {
  promise: Promise<unknown> | undefined;
}

export const LoadingPromiseButton: FC<LoadingPromiseButtonProps> = ({
  promise,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!promise) {
      return;
    }
    (async function () {
      setLoading(true);
      await promise;
      setLoading(false);
    })();
  }, [promise]);
  return <LoadingButton loading={loading} {...props} />;
};

export const LoadingPromiseButtonAdvanced: FC<
  Omit<LoadingButtonProps, 'loading'>
> = ({ onClick: onClickOriginal, ...props }) => {
  const [loading, setLoading] = useState(false);

  const onClick = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      try {
        setLoading(true);
        await onClickOriginal?.(e);
      } finally {
        setLoading(false);
      }
    },
    [onClickOriginal]
  );

  return <LoadingButton loading={loading} {...props} onClick={onClick} />;
};
