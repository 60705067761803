import { styled } from '@mui/material';
import { Box } from '../../index';

export const ErrorContainer = styled(Box)`
  width: 100%;
  height: 94vh;
  overflow: hidden;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
