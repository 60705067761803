import React from 'react';
import { Label } from './Label';
import type { LabelProps } from './Label';

interface Props extends LabelProps {
  children: number;
}

export function NumberLabel(props: Props): JSX.Element {
  return (
    <Label {...props}>{new Intl.NumberFormat().format(props.children)}</Label>
  );
}
