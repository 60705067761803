import { useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { List, Box, ListSubheader } from '@mui/material';
// type
import type { NavSectionProps } from '../type';
//
import { NavListRoot } from './NavList';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.overline,
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

export function NavSection({
  navConfig,
  isCollapse = false,
  ...other
}: NavSectionProps): JSX.Element {
  const [openMenuIndex, setOpenMenuIndex] = useState<number | undefined>(
    undefined
  );

  return (
    <Box {...other}>
      {navConfig.map((group) => (
        <List key={group.subheader} disablePadding>
          {group.items.map((list, index) => (
            <NavListRoot
              key={list.title}
              list={list}
              isCollapse={isCollapse}
              index={index}
              openMenuIndex={openMenuIndex}
              setOpenMenuIndex={setOpenMenuIndex}
            />
          ))}
        </List>
      ))}
    </Box>
  );
}
