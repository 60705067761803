import { useEffect } from 'react';

export const useArrowKeys = (
  open: boolean,
  onLeft: () => void,
  onRight: () => void
): void => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft') {
        onLeft();
      }
      if (event.key === 'ArrowRight') {
        onRight();
      }
    };

    if (open) {
      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
    return undefined;
  }, [open, onLeft, onRight]);
};
