import React from 'react';
import type { AvatarProps } from '@mui/material';
import { useTheme } from '@mui/material';
import { styled } from '@mui/material';
import { Avatar } from '@mui/material';

import { getNameInitials } from './utils/getNameInitials';

export interface NameAvatarProps extends AvatarProps {
  name: string;
  gender?: string;
  fontSize?: number;
}

export const NameAvatar: React.FC<NameAvatarProps> = ({
  name,
  gender,
  fontSize = 16,
  ...props
}) => {
  const theme = useTheme();
  const nameInitials = React.useMemo(() => {
    return getNameInitials(name);
  }, [name]);

  const color = React.useMemo(() => {
    let color = '';
    switch (gender) {
      case 'Female':
        color = theme.palette.secondary.main;
        break;
      default:
        color = theme.palette.primary.main;
        break;
    }
    return color;
  }, [gender, theme]);

  return (
    <StyledAvatar alt={name} color={color} fontSize={fontSize} {...props}>
      {nameInitials}
    </StyledAvatar>
  );
};

export const StyledAvatar = styled(Avatar)<{ color: string; fontSize: number }>(
  ({ theme, color, fontSize }) => ({
    padding: theme.spacing(0),
    fontSize,
    fontWeight: 700,
    color: color,
    backgroundColor: theme.palette.grey[300],
  })
);
