import type { FC } from 'react';
import { ReceiptStatusValues } from '@pflegenavi/shared/api';
import { Label } from './Label';
import { useTranslation } from 'react-i18next';

export const ReceiptStatusLabel: FC<{
  status: ReceiptStatusValues | 'Cancelled';
  // eslint-disable-next-line complexity
}> = ({ status }) => {
  const { t } = useTranslation();
  return (
    <Label
      variant="ghost"
      color={
        (status === ReceiptStatusValues.SUBMITTED && 'success') ||
        (status === ReceiptStatusValues.DRAFT && 'default') ||
        ((status === ReceiptStatusValues.INCOMPLETE ||
          status === 'Cancelled') &&
          'error') ||
        'default'
      }
    >
      {t(`receipts.status.${status}`)}
    </Label>
  );
};
