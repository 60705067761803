import type { FC, PropsWithChildren } from 'react';
import { forwardRef } from 'react';
import type { CustomContentProps } from 'notistack';
import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack';
import type { AlertColor } from '@mui/material';
import { Alert as MuiAlert } from '@mui/material';

const makeSnackbar = (severity: AlertColor, color: string) =>
  forwardRef<HTMLDivElement, Omit<CustomContentProps, 'action'>>(
    (props, ref) => {
      const { id, message, ...other } = props;

      return (
        <MuiAlert
          elevation={6}
          ref={ref}
          {...other}
          severity={severity}
          style={{
            backgroundColor: color,
          }}
          variant="filled"
        >
          {message}
        </MuiAlert>
      );
    }
  );

const SuccessSnackbar = makeSnackbar('success', '#67CC5D');
const ErrorSnackbar = makeSnackbar('error', '#FF4842');
const InfoSnackbar = makeSnackbar('info', '#1890FF');
const WarnSnackbar = makeSnackbar('warning', '#FFC107');

export const SnackbarProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <NotistackSnackbarProvider
      maxSnack={3}
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      Components={{
        success: SuccessSnackbar,
        error: ErrorSnackbar,
        warning: WarnSnackbar,
        info: InfoSnackbar,
      }}
    >
      {children}
    </NotistackSnackbarProvider>
  );
};
