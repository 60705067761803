import { IconButton } from '@mui/material';
import { Iconify } from './Iconify';
import React from 'react';

export const CloseModalIcon = ({
  onClick,
}: {
  onClick: VoidFunction;
}): JSX.Element => {
  return (
    <IconButton
      name={'closeModalIcon'}
      tabIndex={-1}
      onClick={onClick}
      sx={{ p: '16px' }}
    >
      <Iconify
        tabIndex={-1}
        icon={'eva:close-fill'}
        position="absolute"
        width={20}
      />
    </IconButton>
  );
};
