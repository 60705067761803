import type { FC } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import './react-datepicker.css';
import { useLocale } from '@pflegenavi/frontend/localization';
import de from 'date-fns/locale/de';
import { InputLabel, Stack, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { endOfDay, startOfDay } from 'date-fns';

registerLocale('de-AT', de);

interface DateRangeProps {
  range?: {
    from?: Date;
    to?: Date;
  };
  onChange: (value: { from?: Date; to?: Date }) => void;
  label: string;
}
export const DateRange: FC<DateRangeProps> = ({ label, range, onChange }) => {
  const { t } = useTranslation();
  const locale = useLocale();
  const dateFormat = locale === 'de-AT' ? 'dd.MM.yyyy' : undefined;
  return (
    <Stack gap={0}>
      <InputLabel>{label}</InputLabel>
      <DatePicker
        formatWeekDay={(day) => day.slice(0, 1)}
        placeholderText={t('table.filters.date-range.placeholder')}
        dateFormat={dateFormat}
        onKeyDown={(e) => e.preventDefault()}
        isClearable={true}
        customInput={<TextField />}
        locale={locale}
        selected={range?.from ?? null}
        startDate={range?.from ?? null}
        endDate={range?.to ?? null}
        monthsShown={2}
        selectsRange
        onChange={(date) => {
          const startDate = date[0] ?? undefined;
          const endDate = date[1] ?? undefined;

          onChange({
            from: startDate ? startOfDay(startDate) : undefined,
            to: endDate ? endOfDay(endDate) : undefined,
          });
        }}
      />
    </Stack>
  );
};
