// Since multiple rows might queue a snackbar at the same time, we need to make sure that only one is shown
import { useSnackbar } from 'notistack';
import { useCallback, useRef } from 'react';

export const useEnqueueSnackbarOnce = (): ((
  message: string,
  options: { variant: 'success' | 'error' }
) => void) => {
  const { enqueueSnackbar } = useSnackbar();
  const snackbarShownRef = useRef(false);

  const enqueueSnackbarOnce = useCallback(
    (message: string, options: { variant: 'success' | 'error' }) => {
      if (!snackbarShownRef.current) {
        enqueueSnackbar(message, options);
        snackbarShownRef.current = true;
      }
    },
    [enqueueSnackbar]
  );

  return enqueueSnackbarOnce;
};
