import type { History, Transition } from 'history';
import { useCallback, useContext, useEffect, useState } from 'react';
import type { Navigator } from 'react-router';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

type ExtendNavigator = Navigator & Pick<History, 'block'>;

export function useBlocker(
  blocker: (tx: Transition) => void,
  when = true
): {
  unblock: () => void;
} {
  const { navigator } = useContext(NavigationContext);

  const [unblock, setUnblock] = useState<() => void>(() => () => {
    return;
  });

  useEffect(() => {
    if (!when) {
      return;
    }

    const unblock = (navigator as ExtendNavigator).block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    setUnblock(() => unblock);

    // eslint-disable-next-line consistent-return
    return unblock;
  }, [navigator, blocker, when]);

  return { unblock };
}

export function usePrompt(
  message: string,
  when = true
): {
  unblock: () => void;
} {
  const blocker = useCallback(
    (tx: Transition) => {
      if (window.confirm(message)) {
        tx.retry();
      }
    },
    [message]
  );

  return useBlocker(blocker, when);
}
