import type { FC } from 'react';
import { Step, StepLabel, Stepper } from '@mui/material';

interface ChangeCashStepperProps {
  activeStep: number;
  labels: string[];
}

export const CustomStepper: FC<ChangeCashStepperProps> = ({
  activeStep,
  labels,
}) => {
  return (
    <Stepper activeStep={activeStep} alternativeLabel>
      {labels.map((label) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};
