import type { FC, PropsWithChildren } from 'react';
import { Suspense, useEffect, useRef } from 'react';
import { Stack, useTheme } from '@mui/material';

const Fallback = () => {
  const progressRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  useEffect(() => {
    let progress = 0;
    let timer: ReturnType<typeof window.requestAnimationFrame> | undefined;
    const callback = () => {
      if (timer) {
        window.cancelAnimationFrame(timer);
      }
      timer = window.requestAnimationFrame(() => {
        progress = progress >= 100 ? 100 : progress + 1;
        if (progressRef.current) {
          progressRef.current.style.width = `${progress}%`;
        }
        callback();
      });
    };

    callback();
    return () => {
      if (timer) {
        window.cancelAnimationFrame(timer);
      }
    };
  }, []);

  return (
    <Stack
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        height: '4px',
        backgroundColor: theme.palette.secondary.lighter,
      }}
    >
      <div
        ref={progressRef}
        style={{
          height: '4px',
          backgroundColor: theme.palette.secondary.main,
          transition: 'width .1s ease',
          width: '0%',
        }}
      />
    </Stack>
  );
};

export const DefaultSuspense: FC<PropsWithChildren> = ({
  children,
}): JSX.Element => {
  return <Suspense fallback={<Fallback />}>{children}</Suspense>;
};
