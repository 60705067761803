import { differenceInMilliseconds } from 'date-fns';
import { useCallback, useEffect, useRef } from 'react';

interface UseIdleTimeoutCallbackProps {
  idleTime: number;
  callback: () => void;
}

export function useIdleTimeoutCallback({
  idleTime,
  callback,
}: UseIdleTimeoutCallbackProps): void {
  const lastActiveTime = useRef<Date | null>(null);

  const handleUserAction = useCallback(() => {
    const now = new Date();
    if (
      differenceInMilliseconds(now, lastActiveTime.current as Date) >= idleTime
    ) {
      callback();
    }
    lastActiveTime.current = now;
  }, [callback, idleTime]);

  useEffect(() => {
    window.addEventListener('mousemove', handleUserAction);
    window.addEventListener('keydown', handleUserAction);
    window.addEventListener('scroll', handleUserAction);

    // set the last active time as soon as the page is loaded
    lastActiveTime.current = new Date();

    return () => {
      window.removeEventListener('mousemove', handleUserAction);
      window.removeEventListener('keydown', handleUserAction);
      window.removeEventListener('scroll', handleUserAction);
    };
  }, [handleUserAction]);
}
