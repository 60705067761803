import type { FC } from 'react';
import React from 'react';
import { Label } from './Label';
import { useTranslation } from 'react-i18next';
import { useFormatting } from '@pflegenavi/frontend/localization';

interface OverdueDaysLabelProps {
  daysOverdue: number;
  negative: boolean;
}

export const OverdueDaysLabel: FC<OverdueDaysLabelProps> = ({
  daysOverdue,
  negative,
}) => {
  const { t } = useTranslation();
  const { fNumber } = useFormatting();

  return (
    <Label variant="ghost" color={negative ? 'error' : 'warning'}>
      {fNumber(daysOverdue)}{' '}
      {daysOverdue === 1 ? t('common.days_one') : t('common.days')}
    </Label>
  );
};
