import HairdresserIcon from '../assets/receipt-types/hairdresser.svg';
import PharmacyIcon from '../assets/receipt-types/pharmacy.svg';
import PedicureIcon from '../assets/receipt-types/pedicure.svg';
import ManicureIcon from '../assets/receipt-types/manicure.svg';
import SupermarketIcon from '../assets/receipt-types/supermarket.svg';
import ResidentCashIcon from '../assets/receipt-types/resident-cash.svg';
import OtherIcon from '../assets/receipt-types/other.svg';

export const logosMapping = new Map<string, any>([
  // receipt types
  ['Hairdresser', HairdresserIcon],
  ['Pharmacy', PharmacyIcon],
  ['Pedicure', PedicureIcon],
  ['Manicure', ManicureIcon],
  ['Supermarket', SupermarketIcon],
  ['ResidentCash', ResidentCashIcon],
  ['Other', OtherIcon],
  ['Friseur', HairdresserIcon],
  ['Apotheke', PharmacyIcon],
  ['Pediküre', PedicureIcon],
  ['Maniküre', ManicureIcon],
  ['Supermarkt', SupermarketIcon],
  ['Barauslage', ResidentCashIcon],
  ['Andere', OtherIcon],
  ['_unknown', OtherIcon],
]);
