import React from 'react';

import type { LinkProps as MuiLinkProps } from '@mui/material/Link';
import { default as MuiLink } from '@mui/material/Link';

import { Link as RouterLink } from 'react-router-dom';

interface LinkProps extends MuiLinkProps {
  to: string;
}

export function Link({ to, ...props }: LinkProps): JSX.Element {
  return (
    <MuiLink
      style={{ fontFamily: 'Lato' }}
      to={to}
      component={RouterLink}
      {...props}
    />
  );
}
