import type { Theme } from '@mui/material/styles';
import type { LabComponents } from '@mui/lab/themeAugmentation';

// ----------------------------------------------------------------------

export default function LoadingButton(
  theme: Theme
): Pick<LabComponents, 'MuiLoadingButton'> {
  return {
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          '&.MuiButton-text': {
            '& .MuiLoadingButton-startIconPendingStart': {
              marginLeft: 0,
            },
            '& .MuiLoadingButton-endIconPendingEnd': {
              marginRight: 0,
            },
          },
        },
      },
    },
  };
}
