import type { ProviderContext } from 'notistack';
import { useSnackbar } from 'notistack';
import * as Sentry from '@sentry/react';
import { useCallback } from 'react';

type SnackbarParams = Parameters<ProviderContext['enqueueSnackbar']>;

interface ErrorSnackBarResult {
  enqueueErrorSnackbar: (
    error: any,
    message: SnackbarParams[0],
    options?: SnackbarParams[1]
  ) => void;
}

export function useErrorSnackbar(): ErrorSnackBarResult {
  const { enqueueSnackbar } = useSnackbar();

  const enqueueErrorSnackbar = useCallback(
    (
      error: Error | string,
      message: SnackbarParams[0],
      options?: SnackbarParams[1]
    ) => {
      // eslint-disable-next-line no-console
      console.error(error);

      Sentry.captureException(error);

      enqueueSnackbar(message, {
        variant: 'error',
        ...options,
      });
    },
    [enqueueSnackbar]
  );

  return {
    enqueueErrorSnackbar,
  };
}
