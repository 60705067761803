import React from 'react';
import type { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox';
import { default as MuiCheckbox } from '@mui/material/Checkbox';

/* eslint-disable-next-line */
interface CheckboxProps extends MuiCheckboxProps {}

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export function Checkbox({ ...props }: CheckboxProps): JSX.Element {
  return <MuiCheckbox {...props} {...label} />;
}
