import { useState } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useFormattedIban = () => {
  const [spacedIban, setSpacedIban] = useState('');
  const formatIban = (unformattedIban: string) => {
    const formattedValue = chunkSubstr(unformattedIban.replace(/\s/g, ''), 4);
    setSpacedIban(formattedValue.join(' '));
  };
  return {
    spacedIban,
    formatIban,
  };
};

function chunkSubstr(str: string, size: number): string[] {
  const numChunks = Math.ceil(str.length / size);
  const chunks = new Array(numChunks);

  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str.substr(o, size);
  }
  return chunks;
}
