// @mui
import { Checkbox, Typography, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

interface Props {
  dense?: boolean;
  actions?: React.ReactNode;
  rowCount: number;
  numSelected: number;
  onSelectAllRows: (checked: boolean) => void;
}

export default function TableSelectedActions({
  dense,
  actions,
  rowCount,
  numSelected,
  onSelectAllRows,
}: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        px: 4,
        top: 0,
        right: 0,
        left: 0,
        zIndex: 9,
        height: 58,
        borderRadius: 2,
        position: 'absolute',
        width: '100%',
        bgcolor: 'primary.lighter',
      }}
    >
      <Checkbox
        indeterminate={numSelected > 0 && numSelected < rowCount}
        checked={rowCount > 0 && numSelected === rowCount}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          onSelectAllRows(event.target.checked)
        }
        sx={{
          color: 'primary.main',
          '&.Mui-checked': {
            color: 'primary.main',
          },
        }}
      />

      <Typography
        variant="subtitle1"
        sx={{
          ml: 2,
          flexGrow: 1,
          color: 'primary.darker',
          ...(dense && {
            ml: 3,
          }),
        }}
      >
        {numSelected} {t('selected')}
      </Typography>

      {actions && actions}
    </Stack>
  );
}
