// @mui
import type { Theme } from '@mui/material/styles';
import type { SxProps, TableCellProps } from '@mui/material';
import {
  Box,
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';

// ----------------------------------------------------------------------

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
} as const;

// ----------------------------------------------------------------------

export interface HeadCell<TColumns = string> {
  id: TColumns;
  align?: TableCellProps['align'];
  width?: TableCellProps['width'];
  minWidth?: TableCellProps['width'];
  maxWidth?: TableCellProps['width'];
  label: string;
  noSort?: boolean;
}

interface Props<TColumns = string> {
  order?: 'asc' | 'desc';
  orderBy?: TColumns;
  headLabels: Array<HeadCell<TColumns>>;
  rowCount?: number;
  numSelected?: number;
  onSort?: (id: TColumns) => void;
  onSelectAllRows?: (checked: boolean) => void;
  sx?: SxProps<Theme>;
  addActionColumn?: boolean;
  removeFromTabSelection?: boolean;
  additionalHeaderRow?: React.ReactNode;
}

/* eslint-disable complexity */
export default function TableHeadCustom<TColumn extends string>({
  order,
  orderBy,
  rowCount = 0,
  headLabels,
  numSelected = 0,
  onSort,
  onSelectAllRows,
  sx,
  addActionColumn,
  additionalHeaderRow,
  removeFromTabSelection = false,
}: Props<TColumn>): JSX.Element {
  return (
    <TableHead sx={sx}>
      {additionalHeaderRow}
      <TableRow>
        {onSelectAllRows && (
          <TableCell padding="checkbox">
            <Checkbox
              tabIndex={removeFromTabSelection ? -1 : 0}
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onSelectAllRows(event.target.checked)
              }
              color="primary"
              disableFocusRipple
              disableRipple
              disableTouchRipple
            />
          </TableCell>
        )}
        {headLabels.map((headCell) => {
          const onSort_ = headCell.noSort ? undefined : onSort;
          return (
            <TableCell
              key={headCell.id}
              align={headCell.align || 'left'}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{
                width: headCell.width,
                minWidth: headCell.minWidth,
                maxWidth: headCell.maxWidth,
              }}
            >
              {onSort_ ? (
                <TableSortLabel
                  tabIndex={removeFromTabSelection ? -1 : 0}
                  hideSortIcon
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={() => onSort_(headCell.id)}
                  sx={{
                    lineHeight: 'normal',
                  }}
                >
                  {headCell.label.charAt(0).toUpperCase() +
                    headCell.label.slice(1)}
                  {orderBy === headCell.id ? (
                    <Box sx={{ ...visuallyHidden }}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              ) : (
                <TableSortLabel
                  disabled
                  sx={{
                    lineHeight: 'normal',
                  }}
                >
                  {headCell.label}
                </TableSortLabel>
              )}
            </TableCell>
          );
        })}
        {addActionColumn && <TableCell></TableCell>}
      </TableRow>
    </TableHead>
  );
}
