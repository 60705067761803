import type { FC } from 'react';
import React from 'react';
import { IconButton, Stack } from '@mui/material';
import { Iconify, Label } from '../index';
import { YEAR_MONTH_FORMAT } from '@pflegenavi/shared/constants';
import { addMonths, differenceInMonths } from 'date-fns';
import { useLocale } from '@pflegenavi/frontend/localization';

export interface MonthNavigationBlockProps {
  reportingMonth: Date;
  setReportingMonth?: (month: Date) => void;
}

export const MonthNavigationBlock: FC<MonthNavigationBlockProps> = ({
  reportingMonth,
  setReportingMonth,
}) => {
  const shiftDateAndSwitch = (months: number) => {
    setReportingMonth && setReportingMonth(addMonths(reportingMonth, months));
  };

  const locale = useLocale();

  const handlePrevMonth = () => shiftDateAndSwitch(-1);
  const handleNextMonth = () => shiftDateAndSwitch(1);

  const isNextMonthDisabled = () => {
    return differenceInMonths(new Date(), reportingMonth) <= 0;
  };

  return (
    <Stack
      direction={'row'}
      sx={{
        width: 200,
        alignItems: 'center',
      }}
    >
      <IconButton aria-label="prevMonth" onClick={handlePrevMonth}>
        <Iconify icon={'eva:arrow-ios-back-fill'} />
      </IconButton>
      <Label sx={{ flex: 1 }}>
        {reportingMonth.toLocaleDateString(locale, YEAR_MONTH_FORMAT)}
      </Label>
      <IconButton
        aria-label="nextMonth"
        onClick={handleNextMonth}
        disabled={isNextMonthDisabled()}
      >
        <Iconify icon={'eva:arrow-ios-forward-fill'} />
      </IconButton>
    </Stack>
  );
};
