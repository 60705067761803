import React from 'react';
import type { FC } from 'react';

import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  useTheme,
} from '@mui/material';
import { Iconify } from './Iconify';

interface Option {
  label: string;
  onClick: () => void;
  disabled?: boolean;
}

export interface DropdownSplitButtonProps {
  options: Option[];
  width?: number | 'fit-content';
  variant?: 'text' | 'outlined' | 'contained';
  primaryButtonDisabled?: boolean;
  disabled?: boolean;
}

export const DropdownSplitButton: FC<DropdownSplitButtonProps> = ({
  options,
  variant = 'contained',
  disabled = false,
  primaryButtonDisabled,
  width = 180,
}) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        disabled={disabled}
        variant={variant}
        ref={anchorRef}
        aria-label="split button"
        disableElevation={true}
        sx={{ width, boxShadow: 'none' }}
      >
        <Button
          disabled={primaryButtonDisabled}
          onClick={options[0].onClick}
          sx={{ mr: 0.1, flex: 1 }}
        >
          {options[0].label}
        </Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <Iconify icon="tabler:chevron-down" width={20} height={20} />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
          width,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
              border: `1px solid ${theme.palette.divider}`,
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option) => (
                    <MenuItem
                      sx={{ fontSize: 15, fontWeight: 600 }}
                      key={option.label}
                      onClick={() => {
                        option.onClick();
                        setOpen(false);
                      }}
                      disabled={option.disabled}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
