import TableNoData from './TableNoData';
import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const TableNoDataWithImage = ({
  colSpan,
  text,
  image,
  height,
  children,
}: {
  colSpan: number;
  text: string;
  image: string;
  height: number;
  children?: React.ReactElement;
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <TableNoData colSpan={colSpan} isNotFound>
      <img
        alt={t('emptyContent-img')}
        src={image}
        style={{ marginBottom: 35 }}
        height={height}
        width={300}
      />
      <Typography variant="inherit" fontWeight="700" sx={{ mb: 2 }}>
        {text}
      </Typography>
      {children}
    </TableNoData>
  );
};

export default TableNoDataWithImage;
