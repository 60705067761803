import type { FC } from 'react';
import React, { useMemo } from 'react';
import { Iconify } from './Iconify';
import { Stack } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { TruncatedTooltipText } from './TruncatedTooltipText';

interface InitiatorProps {
  initiatedBy: 'system' | 'familyMember' | 'employee' | 'disabled';
  name: string;
  translationKeys: {
    employee: string;
    familyMember: string;
    system: string;
    disabled: string;
  };
  iconSize?: number;
  textLineHeight?: number;
}

export const Initiator: FC<InitiatorProps> = ({
  initiatedBy,
  name,
  translationKeys,
  iconSize = 20,
  textLineHeight = 1.2,
}) => {
  const { t } = useTranslation();

  const [icon, translation] = useMemo(() => {
    if (initiatedBy === 'employee') {
      return ['mdi:user', t(translationKeys.employee)];
    }
    if (initiatedBy === 'familyMember') {
      return ['mdi:account-group', t(translationKeys.familyMember)];
    }
    if (initiatedBy === 'disabled') {
      return ['mdi:cog-stop', t(translationKeys.disabled)];
    }
    return ['mdi:cog', t(translationKeys.system)];
  }, [initiatedBy, translationKeys, t]);

  return (
    <Stack
      direction="row"
      gap={1}
      alignItems="flex-end"
      sx={{ maxWidth: '100%' }}
    >
      <Tooltip title={translation} arrow placement="right">
        <Stack>
          <Iconify
            sx={{
              width: iconSize,
              height: iconSize,
            }}
            icon={icon}
          />
        </Stack>
      </Tooltip>
      <TruncatedTooltipText
        variant="subtitle2"
        sx={{ lineHeight: textLineHeight }}
        text={name}
      />
    </Stack>
  );
};
