import type { FC } from 'react';
import React from 'react';
import { IconButton, Stack } from '@mui/material';
import { Iconify, Label } from '../index';
import { addYears, differenceInYears } from 'date-fns';

export interface YearNavigationBlockProps {
  reportingYear: Date;
  setReportingYear: (year: Date) => void;
}

export const YearNavigationBlock: FC<YearNavigationBlockProps> = ({
  reportingYear,
  setReportingYear,
}) => {
  const shiftDateAndSwitch = (years: number) => {
    setReportingYear(addYears(reportingYear, years));
  };

  const handlePrevYear = () => shiftDateAndSwitch(-1);
  const handleNextYear = () => shiftDateAndSwitch(1);

  const isNextYearDisabled = differenceInYears(new Date(), reportingYear) <= 0;

  return (
    <Stack
      direction={'row'}
      sx={{
        alignItems: 'center',
      }}
    >
      <IconButton aria-label="prevYear" onClick={handlePrevYear}>
        <Iconify icon={'eva:arrow-ios-back-fill'} />
      </IconButton>
      <Label variant="filled" color="info" sx={{ bgcolor: 'primary.light' }}>
        {reportingYear.getFullYear()}
      </Label>
      <IconButton
        aria-label="nextYear"
        onClick={handleNextYear}
        disabled={isNextYearDisabled}
      >
        <Iconify icon={'eva:arrow-ios-forward-fill'} />
      </IconButton>
    </Stack>
  );
};
