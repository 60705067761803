import type { StandardTextFieldProps } from '@mui/material';
import { InputAdornment } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { NumberInputStateless } from './FNumberInputStateless';
import type { NestedKeysToObject } from './FTextInput';
import type { FormikProps } from 'formik';
import { getIn } from 'formik';

type StandardTextFieldPropsWithFilled = Omit<
  StandardTextFieldProps,
  'variant' | 'onChange'
> & {
  variant?: 'standard' | 'filled';
};

interface MoneyInputProps extends StandardTextFieldPropsWithFilled {
  allowNegative?: boolean;
  currencySymbol?: string;
  maxValue?: number;
  value?: number;
  onChange?: (value: number | undefined) => void;
  inputRef?: React.RefObject<HTMLInputElement>;
}

interface FMoneyInputProps<T extends string, V extends NestedKeysToObject<T>>
  extends Omit<MoneyInputProps, 'value'> {
  formik: FormikProps<V>;
  name: T;
  defaultValue?: T;
}

export const FMoneyInput = <
  Field extends string,
  V extends NestedKeysToObject<Field>
>({
  formik,
  name,
  defaultValue,
  onChange,
  ...props
}: FMoneyInputProps<Field, V>): JSX.Element => {
  const formikValue = getIn(formik.values, name);

  const { setFieldValue } = formik;
  const onChangeCallback = useCallback(
    (value: number | undefined) => {
      onChange ? onChange(value) : setFieldValue(name, value);
    },
    [onChange, setFieldValue, name]
  );

  return (
    <MoneyInput
      value={defaultValue ?? formikValue}
      onChange={onChangeCallback}
      disabled={formik.isSubmitting}
      onBlur={formik.handleBlur}
      name={name}
      helperText={getIn(formik.touched, name) && getIn(formik.errors, name)}
      error={Boolean(getIn(formik.errors, name) && getIn(formik.touched, name))}
      {...props}
    />
  );
};

const getEurosAndCents = (value?: number): string => {
  if (value === undefined) {
    return '';
  }
  const euros = Math.trunc(value / 100);
  const cents = Math.abs(value % 100)
    .toString()
    .padStart(2, '0');

  return `${euros}.${cents}`;
};

export const MoneyInput: React.FC<MoneyInputProps> = ({
  inputProps,
  InputProps,
  InputLabelProps,
  allowNegative = false,
  currencySymbol = '€',
  maxValue = 99_999_99 /* ¢ */,
  value,
  onChange,
  onBlur: onBlurOriginal,
  ...props
}) => {
  const [stringValue, setStringValue] = React.useState<string | undefined>(
    value !== undefined ? getEurosAndCents(value) : undefined
  );

  const originalRef = props.inputRef;
  const inputRef = React.useRef<HTMLInputElement>(null);
  const usedRef = originalRef ?? inputRef;

  useEffect(() => {
    // Do not update the state while focused
    if (usedRef.current && usedRef.current.matches(':focus')) {
      return;
    }

    setStringValue((oldStringValue) => {
      // Special case: -0
      if (oldStringValue === '-0' && value === 0) {
        return `-0`;
      }

      return value !== undefined ? getEurosAndCents(value) : undefined;
    });
  }, [value, usedRef]);

  // eslint-disable-next-line complexity
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const eventValue = event.target.value;
    const parsedValue = parseFloat(eventValue.trim());

    if (eventValue === '') {
      setStringValue('');
      onChange && onChange(undefined);
      return;
    }

    if (isNaN(parsedValue)) {
      onChange && onChange(undefined);
      return;
    }

    const centValue = Math.round(parsedValue * 100);

    if (maxValue !== undefined && Math.abs(centValue) > maxValue) {
      return;
    }

    // Disallow leading zeros
    if (/^-?0\d+/.test(eventValue)) {
      return;
    }

    setStringValue(eventValue);

    if (value === parsedValue) {
      // No change, don't update
      return;
    }

    onChange && onChange(centValue);
  };

  const onBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setStringValue(getEurosAndCents(value));
      onBlurOriginal?.(event);
    },
    [onBlurOriginal, value]
  );

  const onFocus = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    const target = event.target;
    target.select();
  }, []);

  return (
    <NumberInputStateless
      inputRef={usedRef}
      allowNegative={allowNegative}
      allowDecimal={true}
      InputLabelProps={InputLabelProps}
      InputProps={{
        startAdornment:
          currencySymbol !== undefined ? (
            <InputAdornment disablePointerEvents position="start">
              {currencySymbol}
            </InputAdornment>
          ) : undefined,
        ...InputProps,
      }}
      onFocus={onFocus}
      value={stringValue ?? ''}
      onChange={onInputChange}
      onBlur={onBlur}
      {...props}
    />
  );
};
