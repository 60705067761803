import type { Components, Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function CssBaseline(
  theme: Theme
): Pick<Components, 'MuiCssBaseline'> {
  return {
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          margin: 0,
          padding: 0,
          boxSizing: 'border-box',
          '&::-webkit-scrollbar': {
            width: 7,
            height: 7,
          },
          '&::-webkit-scrollbar-track': {
            background: 'inherit',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'rgba(136, 136, 136, 0.6)',
            borderRadius: 20,
          },
        },
        html: {
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
        },
        body: {
          width: '100%',
          height: '100%',
          '&::-webkit-scrollbar': {
            width: 10,
            height: 10,
          },
        },
        '#root': {
          width: '100%',
          height: '100%',
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
          },
        },
        img: {
          display: 'block',
          maxWidth: '100%',
        },
      },
    },
  };
}
