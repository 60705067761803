import type { FC } from 'react';
import React from 'react';
import type { TypographyProps } from '@mui/material/Typography';
import Typography from '@mui/material/Typography';

export const OneLineTypography: FC<TypographyProps> = ({
  children,
  ...otherProps
}) => {
  return (
    <Typography {...otherProps} noWrap lineHeight={1}>
      {children}
    </Typography>
  );
};
